import React, { useState } from "react";
import {
  Paper,
  Typography,
  Slider,
  IconButton,
  Collapse,
  Box,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const CustomSlider = ({
  temperature,
  topP,
  onTemperatureChange,
  onTopPChange,
}) => {
  const [showTempInfo, setShowTempInfo] = useState(false);
  const [showTopPInfo, setShowTopPInfo] = useState(false);

  const marks = [
    { value: 0, label: "0" },
    { value: 0.2, label: "0.2" },
    { value: 0.4, label: "0.4" },
    { value: 0.6, label: "0.6" },
    { value: 0.8, label: "0.8" },
    { value: 1, label: "1.0" },
  ];

  return (
    <div className="bg-white w-full mt-4 p-6 rounded-xl">
      <h5 className="sm:text-lg text-sm font-medium text-[#030C15]">
        Fine-tune your AI Model's creativity and response consistency
      </h5>
      <Box sx={{ maxWidth: 600, marginLeft: 1, mt: 2 }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
            >
              Temperature
            </Typography>
            <Tooltip title="Click for more information">
              <IconButton
                size="small"
                onClick={() => setShowTempInfo(!showTempInfo)}
                sx={{ ml: 1 }}
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Collapse in={showTempInfo}>
            <Paper variant="outlined" sx={{ p: 2, mb: 2, bgcolor: "grey.50" }}>
              <Typography variant="body2">
                Temperature controls randomness in responses. Higher values
                (0.8-1.0) make responses more creative and varied, while lower
                values (0.2-0.5) make them more focused and deterministic.
              </Typography>
            </Paper>
          </Collapse>
          <Typography
            variant="body2"
            sx={{ ml: "auto", fontSize: "1.2rem", fontWeight: "bold" }}
          >
            {temperature.toFixed(1)}
          </Typography>
          <Slider
            value={temperature}
            onChange={(_, value) => onTemperatureChange(value)}
            max={1}
            step={0.1}
            marks={marks}
            valueLabelDisplay="auto"
            sx={{ mt: 1, color: "#1975D2" }}
          />
        </Box>

        {/* Top P Control */}
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
            >
              Top P
            </Typography>
            <Tooltip title="Click for more information">
              <IconButton
                size="small"
                onClick={() => setShowTopPInfo(!showTopPInfo)}
                sx={{ ml: 1 }}
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Collapse in={showTopPInfo}>
            <Paper variant="outlined" sx={{ p: 2, mb: 2, bgcolor: "grey.50" }}>
              <Typography variant="body2">
                Top P (nucleus sampling) controls response diversity by limiting
                cumulative probability. Lower values (0.1-0.3) ensure more
                focused responses, while higher values (0.7-1.0) allow more
                variety.
              </Typography>
            </Paper>
          </Collapse>
          <Typography
            variant="body2"
            sx={{ ml: "auto", fontSize: "1.2rem", fontWeight: "bold" }}
          >
            {topP.toFixed(1)}
          </Typography>
          <Slider
            value={topP}
            onChange={(_, value) => onTopPChange(value)}
            max={1}
            step={0.1}
            marks={marks}
            valueLabelDisplay="auto"
            sx={{ mt: 1, color: "#1975D2" }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CustomSlider;
