import React from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import axios from "axios";
import Slide from "@mui/material/Slide";
import { isMobile } from "react-device-detect";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import { styled } from "@mui/material/styles";
import * as amplitude from "@amplitude/analytics-browser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentDialog = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);

  const openStripe = async ({ prodId }) => {
    setLoading(true);
    amplitude.track("Click Add Balance - " + prodId);

    const { x_user_ser, email } = await handleFetchUserAttributes();
    const idToken = await getIdToken();

    const backURL = window.location.origin + window.location.pathname;

    const requestBody = {
      email: email,
      x_user_ser: x_user_ser,
      prodId: prodId,
      backURL: backURL,
    };

    const configHeaders = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/user/balance/top-up",
        requestBody,
        configHeaders
      );

      if (response.status === 200) {
        const redirectURL = response.data.url;
        redirectToStripe(redirectURL);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      amplitude.track("Error Add Balance - " + prodId, { error: error });
      setLoading(false);
    }
  };

  const redirectToStripe = (url) => {
    if (isMobile) {
      let redirect = window.open();
      console.log(redirect);
      window.location.href = url;
      onClose();
    } else {
      window.open(url, "_blank");
      onClose();
    }
  };

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      return {
        x_user_ser: userAttributes["custom:user_serial"],
        email: userAttributes.email,
      };
    } catch (error) {
      console.log(error);
    }
  }

  async function getIdToken() {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      return idToken;
    } catch (error) {
      console.log("Error getting ID token:", error);
      return null;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          padding: "30px 0",
          background: "#E8F3FF", // Adjust padding as needed
        },
      }}
    >
      <DialogTitle>
        <Typography
          className="text-xl font-medium mb-6"
          variant="h3"
          textAlign="center"
        >
          Low Balance? Add Credits now.
        </Typography>
      </DialogTitle>
      <Grid
        sx={{
          ".MuiGrid-item": {
            padding: 2,
          },
        }}
        className="bg-[#D3E9FF] py-4 px-16"
        alignItems="center"
        container
        spacing={3}
        justifyContent="space-around"
      >
        <Grid className="p-2" item>
          <Button
            size="large"
            onClick={() => openStripe({ prodId: 1000 })}
            disabled={loading}
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              "&:hover, &.Mui-focusVisible": {
                background: "#1975D2",
                color: "#fff",
              },
              "&.Mui-disabled": {
                background: "#f0f0f0",
                color: "#888",
                cursor: "not-allowed",
              },
            }}
            className="flex items-center px-5 py-2.5 bg-white border border-gray-200 hover:border-blue-500 rounded-full transition-all group"
          >
            <span className="text-gray-800  sm:text-base md:text-lg lg:text-xl group-hover:text-white">
              1,000 Credits
            </span>
            <span className="mx-2 text-gray-300 text-xl">|</span>
            <span className="font-medium text-gray-600 group-hover:text-white sm:text-base md:text-lg lg:text-xl">
              $10
            </span>
            <EastIcon
              size={20}
              className="ml-2 text-blue-500 group-hover:translate-x-1 group-hover:text-white transition-transform"
            />
          </Button>
        </Grid>
        <Grid className="p-2" item>
          <Button
            size="large"
            onClick={() => openStripe({ prodId: 2000 })}
            disabled={loading}
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              "&:hover, &.Mui-focusVisible": {
                background: "#1975D2",
                color: "#fff",
              },
              "&.Mui-disabled": {
                background: "#f0f0f0",
                color: "#888",
                cursor: "not-allowed",
              },
            }}
            className="flex items-center px-5 py-2.5 bg-white border border-gray-200 hover:border-blue-500 rounded-full transition-all group"
          >
            <span className="text-gray-800 sm:text-base md:text-lg lg:text-xl group-hover:text-white">
              2,000 Credits
            </span>
            <span className="mx-2 text-gray-300 text-xl">|</span>
            <span className="font-medium text-gray-600 group-hover:text-white sm:text-base md:text-lg lg:text-xl">
              $20
            </span>
            <EastIcon
              size={20}
              className="ml-2 text-blue-500 group-hover:translate-x-1 group-hover:text-white transition-transform"
            />
          </Button>
        </Grid>
        <Grid className="p-2" item>
          <Button
            size="large"
            onClick={() => openStripe({ prodId: 3000 })}
            disabled={loading}
            sx={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              "&:hover, &.Mui-focusVisible": {
                background: "#1975D2",
                color: "#fff",
              },
              "&.Mui-disabled": {
                background: "#f0f0f0",
                color: "#888",
                cursor: "not-allowed",
              },
            }}
            className="flex items-center px-5 py-2.5 bg-white border border-gray-200 hover:border-blue-500 rounded-full transition-all group"
          >
            <span className="text-gray-800 sm:text-base md:text-lg lg:text-xl group-hover:text-white">
              3,000 Credits
            </span>
            <span className="mx-2 text-gray-300 text-xl">|</span>
            <span className="font-medium text-gray-600 group-hover:text-white sm:text-base md:text-lg lg:text-xl">
              $25
            </span>
            <EastIcon
              size={20}
              className="ml-2 text-blue-500 group-hover:translate-x-1 group-hover:text-white transition-transform"
            />
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <CircularProgress
          size={24}
          sx={{ marginLeft: "auto", marginRight: "auto", marginTop: 1 }}
        />
      ) : (
        <></>
      )}
      <Typography className="my-4 mx-8 text-center text-[12px]" variant="body1">
        You will be redirected to a secured checkout page.
      </Typography>
      <DialogActions className="absolute top-1 right-1">
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
