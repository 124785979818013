import { Box, Paper } from "@mui/material";
import React from "react";
import style from "./style.module.css";
import Divider from "@mui/material/Divider";
import AIExplorerPreview from "views/AIExplorer/AIExplorerHomePreview";
import Demo from "views/authentication/demo";
import { Helmet } from "react-helmet";

const HowItWorks = () => {
  const howItWorks = [
    {
      image: "how_01.svg",
      heading: "Craft Your Prompt",
      paragraph:
        "Enter your question, task, or conversation starter in the provided text box. Be specific to get the most accurate and useful responses.",
      flexReverse: false,
    },
    {
      image: "how_02.svg",
      heading: "Select Your AI Ensemble",
      paragraph:
        "Choose from 2 to 6 AI models from our diverse lineup. Mix and match to leverage the unique strengths of each model. You can now select models for ongoing conversations!",
      flexReverse: true,
    },
    {
      image: "how_03.svg",
      heading: "Initiate the AI Showdown",
      paragraph: `Click "Compare" to see how each selected AI model responds to your prompt. Watch as responses appear side by side for easy comparison.`,
      flexReverse: false,
    },
    {
      image: "how_04.svg",
      heading: "Analyze and Evaluate",
      paragraph:
        "Compare responses for accuracy, creativity, and relevance. Look for nuances in language, depth of understanding, and problem-solving approaches.",
      flexReverse: true,
      tip: "Consider how different models interpret and execute your prompt.",
    },
    {
      image: "how_reply.png",
      heading: "Dive Deeper with Continued Conversations",
      paragraph:
        "Choose to continue the conversation with one or multiple AI models. Explore how different AIs handle follow-up questions or complex dialogues. Switch between models to find the best fit for your ongoing needs.",
      flexReverse: false,
    },
    {
      image: "how_05.svg", // You might need to create this new image
      heading: "Share and Collaborate",
      paragraph: `Found an interesting comparison or conversation? Share it with the community! Generate a unique link to your AI interaction for easy sharing on social media or with colleagues.`,
      flexReverse: true,
      tip: "Invite others to vote on the best responses and contribute to the collective knowledge.",
    },
  ];

  return (
    <Box className="sm:px-8 px-4 py-10">
      <Helmet>
        <title>
          How It Works | thisorthis.ai - AI Model Comparison Platform
        </title>
        <meta
          name="description"
          content="Discover how thisorthis.ai works - our intuitive side-by-side AI model comparison platform. Learn how to effortlessly compare different AI models and make informed decisions."
        />
        <meta
          name="keywords"
          content="AI comparison, machine learning models, side-by-side comparison, AI evaluation, thisorthis.ai"
        />
      </Helmet>
      <h2 className="text-2xl  text-[#3E3E3E] font-semibold">How it Works?</h2>
      <div className="w-full mt-2 mb-2">
        <Demo />
      </div>
      <Divider className="mb-8" />
      <p className="text-center lg:text-xl sm:text-lg text-base text-[#030C15] font-normal mt-4 w-[50%] max-lg:w-[90%] max-md:w-[95%] max-sm:w-full">
        Discover how our platform revolutionizes your AI interactions with
        side-by-side comparisons and ongoing conversations. Follow these steps
        to maximize your AI experience:
      </p>
      <div className="w-full md:mt-16 mt-8">
        {howItWorks.map((elm, i) => (
          <div
            key={i}
            className={`w-full ${i !== 0 ? "lg:mt-32 mt-8" : "mt-8"}  flex ${elm.flexReverse ? "flex-row-reverse" : "flex-row"} justify-between max-lg:flex-col-reverse`}
          >
            <div
              className={`w-[50%] h-full max-lg:w-full max-lg:mt-12 max-lg:flex max-lg:justify-center ${elm.flexReverse ? "lg:pl-12" : ""}`}
            >
              <img
                className="w-full max-lg:w-[95%]"
                src={`/images/how-it-works/${elm.image}`}
                alt=""
              />
            </div>
            <div
              className={`w-[50%] h-full max-lg:w-full ${elm.flexReverse ? "px-4" : "lg:pl-12 lg:pr-4 px-4"}`}
            >
              <h1 className={style.gradient_text}>{`0${i + 1}`}</h1>
              <h3 className="text-[#0A2F54] mt-4 font-semibold text-2xl">
                {elm.heading}
              </h3>
              <p className="text-[#030C15] text-lg mt-2 sm:w-[95%] w-full">
                {elm.paragraph}
              </p>
              {elm.tip && (
                <p className="text-[#0A2F54] font-normal mt-8">
                  <span className="font-medium">Pro Tip: </span>
                  {elm.tip}
                </p>
              )}
            </div>
          </div>
        ))}
        <Divider
          sx={{
            width: "100%",
            borderWidth: 1,
            borderColor: "#1975D2",
            marginTop: "50px",
          }}
        />
      </div>

      <div className="w-full lg:mt-10 mt-16">
        <h2 className="text-[#030C15] sm:text-2xl text-lg font-semibold">
          Customize Your Experience:
        </h2>
        <div className="mt-8">
          <h3 className="sm:text-lg text-base text-[#030C15] font-semibold">
            Personalize your AI interactions for optimal results.
          </h3>
          <p className="sm:text-base text-sm text-[#030C15] mt-2 w-full">
            Save favorite AI model combinations for quick access. Bookmark
            particularly useful conversations or comparisons for future
            reference. Personalize your dashboard to focus on the AI models and
            features most relevant to you.
          </p>
        </div>
        <div
          className={`w-full lg:mt-12 mt-8 flex flex-row-reverse justify-between max-lg:flex-col-reverse`}
        >
          <div className="w-[48%] h-full max-lg:w-full max-lg:mt-8">
            <img
              className="w-full h-auto max-lg:w-[95%]"
              src={`/images/how-it-works/response_type.svg`}
              alt=""
            />
          </div>
          <div className="w-[48%] h-full max-lg:w-full">
            <h3 className="text-[#0A2F54] mt-4 font-semibold sm:text-2xl text-lg">
              <b>Learn and Iterate</b>
            </h3>
            <p className="text-[#030C15] sm:text-base text-sm mt-2 ">
              Use insights from comparisons and community feedback to refine
              your prompts. Discover which AI models excel in different areas to
              inform your future interactions. Regular use of thisorthis.ai can
              significantly enhance your AI utilization skills.
            </p>
          </div>
        </div>
      </div>
      <AIExplorerPreview />
    </Box>
  );
};

export default HowItWorks;
