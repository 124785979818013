import AskPromptComponents from "./AskPromptComponents";
import { Helmet } from "react-helmet";
import AIExplorerPreview from "views/AIExplorer/AIExplorerHomePreview";
import { Paper } from "@mui/material";

const DashboardHomePage = () => {
  return (
    <section className="w-[95%] mx-auto min-h-screen  pb-8 lg:ml-9 max-md:p-2">
      <Helmet>
        <title>AI Model Comparison | Multi-Model Chat | thisorthis.ai</title>
        <meta
          name="description"
          content="Compare AI responses side-by-side, chat with multiple models effortlessly, and make faster decisions. Streamline your AI workflow today."
        />
      </Helmet>
      <AskPromptComponents></AskPromptComponents>
      <Paper className="xl:w-11/12 2xl:w-4/5 mt-4 mx-auto">
        <AIExplorerPreview />
      </Paper>
    </section>
  );
};

export default DashboardHomePage;
