import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "./Auth.css";
import { formFields, components } from "./custom";
import AIModelsGraph from "./AIModelsGraph";
import { Avatar, Box, Grid, Typography, Paper } from "@mui/material";
import aiMoels from "./modelList.json";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { TypeAnimation } from "react-type-animation";
import SignUpDialog from "./SignUpDialog";
import Subscribe from "components/Promptology";
import HomeIntro from "views/how-it-works/HomeIntro";
//import SamplePrompts from "components/SamplePrompts";
import AIExplorerPreview from "views/AIExplorer/AIExplorerHomePreview";
import TestimonialCard from "../../components/Testimonial";
import ErrorDialog from "components/DisableError";

const AuthComponent = () => {
  return (
    <div className="w-full h-full flex flex-col lg:flex-row">
      <SignUpDialog />
      <div className="w-full lg:w-[60%] flex flex-col sm:px-4 mt-10">
        <div className="flex gap-2 items-center">
          <TypeAnimation
            sequence={[
              "Save time and effort",
              2100,
              "Make smarter decisions",
              2100,
              "Boost your productivity",
              2100,
            ]}
            wrapper="span"
            speed={50}
            deletionSpeed={80}
            className="sm:text-4xl text-2xl text-[#373737] font-semibold text-left"
            repeat={Infinity}
          />
          <Typography className="sm:text-2xl text-xl text-[#373737] font-normal mt-2 text-left">
            with side-by-side AI comparisons
          </Typography>
        </div>
        <Typography className="sm:text-xl text-lg text-[#373737] font-normal mt-2 text-left">
          Get the Best AI Answers, All in One Place
        </Typography>
        <Carousel
          interval={2100}
          autoPlay
          showStatus={false}
          showArrows={false}
          stopOnHover={false}
          showThumbs={false}
          infiniteLoop
          omitDeletionAnimation
        >
          <div className="pb-16 flex flex-col ">
            <Grid container spacing={4} marginTop={4} marginLeft={0}>
              {Object.keys(aiMoels).map((parent, i) => (
                <Grid item xs={5} md={3} key={i}>
                  <Box className=" flex items-center">
                    <Box className="w-8 h-8 rounded-full flex justify-center items-center bg-white shadow-md">
                      <Avatar
                        sx={{ width: 50, height: 50 }}
                        className="bg-transparent"
                        alt="Random image"
                        src={aiMoels[parent]}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "16px",
                        color: "#030C15",
                        fontWeight: "600",
                        marginLeft: "15px",
                      }}
                    >
                      {parent}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className=" pb-16 flex flex-col">
            <div className="h-[200px] w-full mt-6">
              <img
                className="h-full object-contain"
                src="/images/this-or-this.svg"
                alt=""
              />
            </div>
          </div>
        </Carousel>
        <div className="lg:hidden mb-5 mt-1">
          <Authenticator formFields={formFields} components={components} />
        </div>
        <HomeIntro />
        <AIExplorerPreview />
        <AIModelsGraph />
        <TestimonialCard />
        <Subscribe />
      </div>
      <div className="w-full lg:w-[40%] sm:py-12 py-4 xl:fixed xl:right-0 xl:bottom-20 xl:top-20 relative lg:h-screen lg:overflow-y-scroll">
        <div className="relative z-10 mt-5 lg:mt-0 hidden lg:block">
          <Authenticator formFields={formFields} components={components} />
        </div>
        <div
          style={{
            position: "absolute",
            transform: "translate(-50%,-50%)",
            left: "50%",
            top: "50%",
          }}
          className="w-full h-[600px] absolute flex items-center justify-center"
        ></div>
      </div>
      <ErrorDialog />
    </div>
  );
};

export default AuthComponent;
