// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const UPDATE_BALANCE = "@customization/UPDATE_BALANCE";
export const UPDATE_HISTORY = "@customization/UPDATE_HISTORY";
export const ALL_PROMPTS = "@customization/ALL_PROMPTS";
export const MY_BALANCE = "@customization/MY_BALANCE";
export const MODEL_DETAILS = "@customization/MODEL_DETAILS";
export const USER_TYPE = "@customization/USER_TYPE";
export const ALL_MODELS = "@customization/ALL_MODELS";
export const IS_REMEMBERED = "@customization/IS_REMEMBERED";
export const SELECTED_TYPES = "@customization/SELECTED_TYPES";
