import { useEffect, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import axios from "axios";

import PaymentDialog from "./PaymentDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import * as amplitude from "@amplitude/analytics-browser";

const Topup = ({ isOpen, isLow }) => {
  const [loadDialog, setLoadDialog] = useState(false);

  useEffect(() => {
    changeState();
  }, [isOpen]);

  const changeState = async () => {
    setLoadDialog(isOpen);
  };

  const handleClick = async (e) => {
    amplitude.track("Topup Dialog Opened");
    setLoadDialog(true);
  };

  const handleCloseDialog = () => {
    setLoadDialog(false);
  };

  return (
    <>
      <Tooltip title="Add Credits">
        <IconButton onClick={handleClick}>
          <AddCircleOutlineIcon
            alt="add_balance"
            sx={{
              padding: ".2em",
              width: 32,
              height: 32,
              color: "#336FD6",
            }}
          />
        </IconButton>
      </Tooltip>
      {loadDialog ? (
        <PaymentDialog open={loadDialog} onClose={handleCloseDialog} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Topup;
