import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  IconButton,
  Paper,
  useTheme,
  Fade,
  Rating,
  styled,
} from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const MAIN_COLOR = "#1975D2";
const TEXT_COLOR = "#3E3E3E";

const QuoteIcon = styled(FormatQuoteIcon)(({ theme }) => ({
  fontSize: 40,
  color: MAIN_COLOR,
  zIndex: 1001,
}));

const testimonials = [
  {
    text: "As a freelancer, I love the pay-as-you-go model. No more juggling subscriptions across different AI platforms - thisorthis.ai gives me everything I need with side-by-side comparisons. I've cut my research time in half and improved the quality of my deliverables!",
    author: "Kai Blackwood",
    role: "Independent Content Strategist",
  },
  {
    text: "The ability to compare AI responses side by side is a game-changer. Before thisorthis.ai, I was copying responses between ChatGPT, Claude, and Gemini manually. Now it's all in one place with a single click. Plus, only paying for what I use means I can scale my usage based on project demands.",
    author: "Zara Noorani",
    role: "Research Analyst",
  },
  {
    text: "As someone who works across different departments, I need versatile AI solutions. thisorthis.ai has become my go-to platform for everything from content creation to data analysis. The instant comparison feature ensures I'm always getting the best possible output without the hassle of managing multiple platforms.",
    author: "Astrid Kovač",
    role: "Operations Director",
  },
  {
    text: "The decision-making process in our team has been completely transformed. Instead of debating which AI to use, we can now compare outputs instantly. No commitments, no subscriptions - just reliable results when we need them. thisorthis.ai has been a game changer for us.",
    author: "Soren Lindegaard",
    role: "Product Development Lead",
  },
];

const TestimonialCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [show, setShow] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextTestimonial();
    }, 8000);

    return () => clearInterval(timer);
  }, [currentIndex]);

  const handleNextTestimonial = () => {
    setShow(false);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
      setShow(true);
    }, 400);
  };

  const handleDotClick = (index) => {
    if (index !== currentIndex) {
      setShow(false);
      setTimeout(() => {
        setCurrentIndex(index);
        setShow(true);
      }, 400);
    }
  };

  return (
    <Box className="mt-8">
      <h3 className="text-2xl text-black font-semibold mb-4">
        Trusted by 5000+ Users
      </h3>
      <Card
        sx={{
          boxShadow: 1,
          padding: "2em",
        }}
      >
        {/* Testimonial Content */}
        <Fade in={show} timeout={500}>
          <Box sx={{ minHeight: "200px" }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.1rem",
                mb: 3,
                fontStyle: "italic",
                color: { TEXT_COLOR },
              }}
            >
              <QuoteIcon /> {testimonials[currentIndex].text}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {testimonials[currentIndex].author}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {testimonials[currentIndex].role}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>

        {/* Navigation Dots */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          {testimonials.map((_, index) => (
            <IconButton
              key={index}
              onClick={() => handleDotClick(index)}
              size="small"
              sx={{ p: 0.5 }}
            >
              <FiberManualRecordIcon
                sx={{
                  fontSize: 12,
                  color:
                    index === currentIndex ? "primary.main" : "action.disabled",
                }}
              />
            </IconButton>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default TestimonialCard;
