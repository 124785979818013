import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const SignUpDialog = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorDescription = searchParams.get("error_description");
    const error = searchParams.get("error");
    console.log(error);
    console.log(errorDescription);

    if (
      error === "invalid_request" &&
      errorDescription === "attributes required: [family_name]"
    ) {
      setOpen(true);
      amplitude.track("Google Signup Error", {
        error: error,
        description: errorDescription,
      });
    } else if (error) {
      amplitude.track("Other Signup Error", { error: error });
    }
  }, [location]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: "1.5rem" }}>
        Oops! Something Went Wrong...
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: "1.2rem" }}>
          {" "}
          <p>
            Looks like we hit a little snag. To get you back on track, please{" "}
            <b>sign up via email.</b> We promise, it’s quick and painless!
          </p>
          <br />
          <p>Think of it as your secret handshake into the club. 🕺💃</p>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpDialog;
