import React, { useState, useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";

const useInAppBrowserDetector = () => {
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);

  useEffect(() => {
    const detectInAppBrowser = () => {
      const userAgent = navigator.userAgent || window.opera;

      // List of known in-app browser strings
      const inAppBrowsers = [
        "FBAN",
        "FBAV", // Facebook
        "Twitter", // Twitter
        "LinkedIn", // LinkedIn
        "Instagram", // Instagram
        "Line", // Line
        "KAKAOTALK", // KakaoTalk
        "WhatsApp", // WhatsApp
        "WeChat", // WeChat
        "FBLC",
        "FBDV",
        "FBSN",
        "FBSS",
        "FBSM",
        "FBMF",
        "FBMO",
        "FBPN",
        "FBUI",
        "FBDV",
        "ASBB",
      ];

      // Find the first matching in-app browser string
      const matchingBrowser = inAppBrowsers.find(
        (browser) => userAgent.indexOf(browser) > -1
      );

      const isInApp = !!matchingBrowser;

      if (isInApp) {
        console.log(`In-app browser detected: ${matchingBrowser}`);
        amplitude.track(`In App Browser - ${matchingBrowser}`, {
          Browser: matchingBrowser,
        });
      }

      setIsInAppBrowser(isInApp);
    };

    detectInAppBrowser();
  }, []);

  return { isInApp: isInAppBrowser };
};

export default useInAppBrowserDetector;
