import axios from "axios";
import { useEffect, useState } from "react";
const useFetchGraphValues = () => {
  const [loading, setLoading] = useState(true);
  const [xAxisData, setXAxisData] = useState(null);
  const [seriesData, setSeriesData] = useState(null);
  const [allPrompts, setAllPrompts] = useState(null);
  const [allResponses, setAllResponses] = useState(null);

  useEffect(() => {
    setLoading(true);
    let analytics_path;
    if (process.env.REACT_APP_ENV === "prod") {
      analytics_path = "/analytics/live";
    } else {
      analytics_path = "/analytics";
    }
    const loadData = async () => {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + analytics_path
      );

      console.log(response.data);

      const labels = Object.keys(response.data.bar_graph);
      const values = Object.values(response.data.bar_graph).map(Number);
      const prompts = response.data.total_prompts;
      const responses = response.data.total_responses;
      setAllPrompts(prompts);
      setAllResponses(responses);
      const totalValueSum = values.reduce(
        (total, current) => total + current,
        0
      );
      // Create the required format for xAxis and series props
      const xAxis = [{ scaleType: "band", data: labels }];
      const series = values.map((value, i) => {
        let percentage = ((value / totalValueSum) * 100).toFixed(2);
        return { id: i, value: percentage, label: labels[i] };
      });

      setXAxisData(xAxis);
      setSeriesData(series);
      setLoading(false);
    };
    loadData();
  }, []);

  return { seriesData, loading, allPrompts, allResponses };
};

export default useFetchGraphValues;
