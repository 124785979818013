/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
let aws_env = process.env.REACT_APP_ENV;
//let aws_env = "prod";
let awsmobile;

const getRedirectUri = () => {
  const hostname = window.location.hostname;

  if (hostname === "localhost") {
    return {
      signIn: "http://localhost:3000/",
      signOut: "http://localhost:3000/",
    };
  } else {
    return {
      signIn: "https://" + hostname + "/",
      signOut: "https://" + hostname + "/",
    };
  }
};

const { signIn, signOut } = getRedirectUri();

if (aws_env === "prod") {
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:949cbf9a-003c-427c-8d83-614f93a8127d",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_mlWdLBCf2",
    aws_user_pools_web_client_id: "iaq2a4mim5ksrltc5p4sa75f4",
    oauth: {
      domain: "auth.thisorthis.ai",
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: signIn,
      redirectSignOut: signOut,
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: ["GOOGLE"],
    aws_cognito_signup_attributes: ["EMAIL", "given_name", "family_name"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };
} else if (aws_env === "dev") {
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:e35d86f1-34de-4b4b-8347-87c93244225a",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_Mdv21ogDD",
    aws_user_pools_web_client_id: "7c823peutvj57bmuou5fh6r3d7",
    oauth: {
      domain: "thisorthis.auth.us-east-1.amazoncognito.com",
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: signIn,
      redirectSignOut: signOut,
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: ["GOOGLE"],
    aws_cognito_signup_attributes: ["EMAIL", "given_name", "family_name"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };
} else if (aws_env === "dev_old") {
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:5b877872-4325-469d-a049-310e8883763a",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_BdonOGpHv",
    aws_user_pools_web_client_id: "7tmvuvupp05221p6e7f12lpds9",
    oauth: {
      domain: "thisorthisai-dev.auth.us-east-1.amazoncognito.com",
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: ["GOOGLE"],
    aws_cognito_signup_attributes: ["EMAIL", "given_name", "family_name"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  };
}

export default awsmobile;
