import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StorageIcon from "@mui/icons-material/Storage";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut, fetchUserAttributes } from "aws-amplify/auth";
import { useNavigate } from "react-router";
import Skeleton from "@mui/material/Skeleton";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import BalanceSection from "../BalanceSection";
import * as amplitude from "@amplitude/analytics-browser";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const ProfileMenu = () => {
  const tab1024 = useMediaQuery("(max-width:1024px)");
  const tab768 = useMediaQuery("(max-width:768px)");
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleProfileOpen = (event) => {
    setAnchorEl(event.currentTarget);
    amplitude.track("Open Profile");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuStyle = {
    color: "#0D2751",
    borderRadius: "2em",
    paddingTop: "1em",
    paddingBottom: "1em",
    "&:hover": {
      background: "#1975D2",
      color: "#fff",
      opacity: 1,
    },
    "&:hover .MuiSvgIcon-root": {
      fill: "#fff",
    },
    "&:active": {
      background: "#1975D2",
      color: "#fff",
    },
  };

  const fetchUserInformation = async () => {
    try {
      const data = await fetchUserAttributes();
      console.log("User Attributes Data:");
      console.log(data);
      setUserData(data);
    } catch (err) {
      console.log(err);
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  };

  async function handleSignOut() {
    try {
      await signOut();
      sessionStorage.clear();
      localStorage.clear();
      amplitude.track("Log Out");
      amplitude.reset();
      navigate("/");
    } catch (error) {
      signOut();
      amplitude.track("Log Out");
      amplitude.reset();
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  }
  const handleListItemClick = (event, route = "") => {
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };

  useEffect(() => {
    fetchUserInformation();
  }, []);
  return (
    <>
      {userData ? (
        <>
          <IconButton
            id="profile_menu_button"
            aria-controls={open ? "profile_menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleProfileOpen}
            className="p-0 ml-4 "
          >
            <Avatar
              alt="Profile Menu"
              {...stringAvatar(
                `${userData?.given_name} ${userData?.family_name}`
              )}
              sx={{
                width: 34,
                height: 34,
                padding: "1em",
                fontSize: "14px",
                bgcolor: "#0D2751",
                color: "#fff",
              }}
            />
          </IconButton>
          <Menu
            id="profile_menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "profile_menu_button",
              sx: {
                px: 2,
              },
            }}
            className="w-[40vh] p-6"
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              key="sldkjfasf1"
              onClick={handleClose}
              className="w-full flex justify-between pl-0"
            >
              <Avatar
                alt="Profile icon"
                {...stringAvatar(
                  `${userData?.given_name} ${userData?.family_name}`
                )}
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: "#0D2751",
                  color: "#fff",
                }}
              />
              <div className="flex flex-col ml-4 w-full">
                <h6 className="text-base font-semibold text-secondary_v2_color">
                  {userData.given_name}&nbsp; {userData.family_name}
                </h6>
                <Typography
                  variant="body1"
                  noWrap
                  className="text-sm text-card_v2_color max-md:w-[75%] w-[90%]"
                >
                  {userData.email}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem
              key="sldkjfasf2"
              sx={MenuStyle}
              className={`w-full flex items-center rounded-[2em] ${tab1024 ? "mt-0" : "mt-4"}`}
              onClick={(event) => handleListItemClick(event, "profile")}
            >
              <AccountCircleIcon className="mr-4 text-[#0D2751]" />
              <h3 className="ml-2 text-base font-medium"> My Profile</h3>
            </MenuItem>
            <MenuItem
              key="sldkjfasf2"
              sx={MenuStyle}
              className={`w-full flex items-center rounded-[2em]`}
              onClick={(event) => handleListItemClick(event, "prompts")}
            >
              <StorageIcon className="mr-4 text-[#0D2751]" />
              <h3 className="ml-2 text-base font-medium"> My Prompts</h3>
            </MenuItem>
            <MenuItem
              key="sldkjfasf3"
              sx={MenuStyle}
              className="w-full flex items-center rounded-[2em]"
              onClick={(event) => handleListItemClick(event, "balance")}
            >
              <AccountBalanceWalletIcon className="text-[rgb(13,39,81)] mr-4" />
              <div className="ml-2 flex-col">
                <h3 className="text-base font-medium"> My Balance</h3>
                {tab768 && <BalanceSection />}
              </div>
            </MenuItem>
            <MenuItem
              key="sldkjfasf4"
              sx={MenuStyle}
              className="w-full flex items-center rounded-[2em]"
              onClick={(event) => handleListItemClick(event, "feedback")}
            >
              <MarkUnreadChatAltIcon className="text-[rgb(13,39,81)] mr-4" />
              <h3 className="ml-2 text-base font-medium"> Feedback</h3>
            </MenuItem>
            {tab1024 ? (
              <>
                {" "}
                <MenuItem
                  key="sldksdfsadfsf2"
                  sx={MenuStyle}
                  className="w-full flex items-center rounded-[2em]"
                  onClick={(event) => handleListItemClick(event, "ai-explorer")}
                >
                  <TipsAndUpdatesIcon className="text-[rgb(13,39,81)] mr-4" />
                  <h3 className="ml-2 text-base font-medium"> AI Explorer</h3>
                </MenuItem>{" "}
                <MenuItem
                  key="sldksdfsadfsf2"
                  sx={MenuStyle}
                  className="w-full flex items-center rounded-[2em]"
                  onClick={(event) =>
                    handleListItemClick(event, "how-it-works")
                  }
                >
                  <HelpIcon className="text-[rgb(13,39,81)] mr-4" />
                  <h3 className="ml-2 text-base font-medium"> How it works?</h3>
                </MenuItem>
                <MenuItem
                  key="sldktwerfsf44"
                  sx={MenuStyle}
                  className="w-full flex items-center rounded-[2em]"
                  onClick={(event) => handleListItemClick(event, "pricing")}
                >
                  <MonetizationOnIcon className="text-[rgb(13,39,81)] mr-4" />
                  <h3 className="ml-2 text-base font-medium"> Pricing</h3>
                </MenuItem>{" "}
              </>
            ) : (
              <></>
            )}
            <MenuItem
              key="sldkjfasf5"
              sx={MenuStyle}
              className="w-full flex items-center rounded-[2em] opacity-55"
              onClick={handleSignOut}
            >
              <LogoutIcon className="mr-4 text-[#0D2751]" />
              <h3 className="ml-2 text-base font-medium">Logout</h3>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Skeleton className="ml-4 " variant="circular" width={32} height={32} />
      )}
    </>
  );
};

export default ProfileMenu;
