import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { fetchUserAttributes } from "aws-amplify/auth";

const ProtectedRoute = ({
  children,
  AuthenticatedComponent,
  PublicComponent,
  layout,
}) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    const setUserId = async () => {
      const { userSerial, given_name, email } =
        await handleFetchUserAttributes();

      if (userSerial && given_name && email) {
        amplitude.setUserId(email);
        const identifyEvent = new amplitude.Identify();
        let uID = parseInt(userSerial);
        identifyEvent.setOnce("first_name", given_name);
        identifyEvent.setOnce("user_serial_id", uID);
        amplitude.identify(identifyEvent);
      }
    };
    if (authStatus === "authenticated") {
      setUserId();
    }
  }, [authStatus]);

  return (
    <>
      {authStatus === "authenticated" ? (
        <AuthenticatedComponent />
      ) : (
        <PublicComponent />
      )}
    </>
  );
};

export default ProtectedRoute;

async function handleFetchUserAttributes() {
  console.log("starting to get now");
  try {
    const userAttributes = await fetchUserAttributes();
    console.log(userAttributes);
    return {
      userSerial: userAttributes["custom:user_serial"],
      given_name: userAttributes.given_name,
      email: userAttributes.email,
    };
  } catch (error) {
    console.log(error);
    console.log("starting to issue now");
    return {
      userSerial: null,
      given_name: null,
      email: null,
    };
  }
}
