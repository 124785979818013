import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import { Helmet } from "react-helmet";

// Import icons
import BoltIcon from "@mui/icons-material/Bolt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CompareIcon from "@mui/icons-material/Compare";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ImageIcon from "@mui/icons-material/Image";
import CelebrationIcon from "@mui/icons-material/Celebration";
import * as amplitude from "@amplitude/analytics-browser";

const PricingExplanation = () => (
  <div className="mb-4 flex items-start mt-2">
    <p className="text-2xl text-[#3E3E3E] font-semibold">
      On-Demand AI - No Subscriptions, No Commitments
    </p>
  </div>
);

const BonusCreditsAlert = () => (
  <div className="bg-[#E3F2FD] p-2 rounded-lg mb-0 flex items-center">
    <CelebrationIcon className="text-[#1975D2] mr-3" />
    <p className="text-base text-[#1975D2] font-semibold">
      Get 15 Bonus Credits when you sign up today!
    </p>
  </div>
);

const faqData = [
  {
    question: "How do I pay for using AI models?",
    answer:
      "Add credits to your wallet and use them with any AI model. Each model usage deducts a specific number of credits based on the model's tier. You can top up your wallet balance anytime to continue using the models.",
  },
  {
    question: "What are credits and how do they work?",
    answer:
      "Credits are our simple way of paying for AI model usage. Instead of calculating tokens, each time you use an AI model, a fixed number of credits is deducted from your wallet based on the model's tier. This makes it easier to understand and predict your usage costs.",
  },
  {
    question: "Is there a minimum top-up amount for the wallet balance?",
    answer:
      "Yes, the minimum amount to top up your wallet balance is $20 to receive 2000 credits. This ensures you have enough credits to use various AI models effectively.",
  },
  {
    question: "Do my credits expire?",
    answer:
      "No, The credits do not expire and you can use them whenever you want to.",
  },
  {
    question: "What happens to my bonus credits?",
    answer:
      "If you have received any bonus credits, they are added immediately upon sign up and never expire. You can use them with any AI model of your choice.",
  },
  {
    question: "Can I use multiple models simultaneously?",
    answer:
      "Yes! You can compare up to 6 different AI models side-by-side with the same prompt. Credits will be deducted for each model used in the comparison. This is a great way to find the best AI model for your specific needs.",
  },
  {
    question: "How can I estimate the cost of my usage?",
    answer:
      "Each AI model is assigned to a specific tier (1-5), and each tier has a fixed credit cost per use. You can see how many credits each model requires before using it, making it easy to plan your usage. Your remaining credit balance is always visible in your dashboard.",
  },
  {
    question: "What happens if I run out of credits?",
    answer:
      "When your credits run low, you'll need to top up your wallet to continue using the AI models. You can add more credits at any time through your dashboard. We'll also notify you when your balance is running low.",
  },
  {
    question: "Are there any additional fees besides the credit cost?",
    answer:
      "No, there are no hidden fees or additional costs. You only pay when you add credits to your wallet. There are no subscription fees, no minimum usage requirements, and no extra charges beyond the credits you use.",
  },
  {
    question: "Can I share my AI conversations with others?",
    answer:
      "Yes! You can share your AI conversations publicly with others. This is a great way to collaborate and showcase interesting AI interactions. Sharing conversations doesn't cost any additional credits.",
  },
  {
    question: "Do I need a credit card to sign up?",
    answer:
      "No, you can sign up and start exploring thisorthis.ai without a credit card. You'll only need a credit/debit card only when you want to purchase additional credits.",
  },
];

const modelTierData = [
  {
    credits: 1,
    models: [
      { name: "Nova Micro", provider: "Amazon" },
      { name: "Nova Lite", provider: "Amazon" },
      { name: "Titan Text G1-Lite", provider: "Amazon" },
      { name: "Titan Text G1-Express", provider: "Amazon" },
      { name: "Llama 3 8B Instruct", provider: "Meta" },
      { name: "Cohere Command Light", provider: "Cohere" },
      { name: "Mistral AI 7B Instruct", provider: "Mistral AI" },
      { name: "Gemini 1.5 Flash", provider: "Google" },
      { name: "GPT 4o mini", provider: "OpenAI" },
      { name: "AI21 Jamba 1.5 Mini", provider: "AI21 Labs" },
      { name: "Llama 3.2 1B", provider: "Meta" },
    ],
  },
  {
    credits: 2,
    models: [
      { name: "ChatGPT-3.5-Turbo", provider: "OpenAI" },
      { name: "Mistral AI 8X7B Instruct", provider: "Mistral AI" },
      { name: "AI21 Jamba Instruct", provider: "AI21 Labs" },
      { name: "Llama 3.2 3B", provider: "Meta" },
    ],
  },
  {
    credits: 3,
    models: [
      { name: "Nova Pro", provider: "Amazon" },
      { name: "Llama 3 70B Instruct", provider: "Meta" },
      { name: "Cohere Command", provider: "Cohere" },
      { name: "Claude Instant", provider: "Anthropic" },
    ],
  },
  {
    credits: 4,
    models: [
      { name: "AI21 Jurassic-2-Mid", provider: "AI21 Labs" },
      { name: "Gemini 1.5 Pro", provider: "Google" },
      { name: "AI21 Jamba 1.5 Large", provider: "AI21 Labs" },
      { name: "Claude 3.5 Haiku", provider: "Anthropic" },
    ],
  },
  {
    credits: 5,
    models: [
      { name: "GPT 4", provider: "OpenAI" },
      { name: "GPT 4-Turbo", provider: "OpenAI" },
      { name: "GPT 4o", provider: "OpenAI" },
      { name: "GPT o1 mini", provider: "OpenAI" },
      { name: "AI21 Jurassic-2-Ultra", provider: "AI21 Labs" },
      { name: "Claude 3.5 Sonnet", provider: "Anthropic" },
      { name: "Claude 2.1", provider: "Anthropic" },
    ],
  },
];

const ModelTiers = () => {
  const [expandedTier, setExpandedTier] = useState(0);

  return (
    <div className="border border-solid border-[#CAE4FF] rounded-lg sm:p-8 p-4 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
      <h2 className="text-2xl text-[#3E3E3E] font-semibold mb-6">
        AI Model Credit Usage
      </h2>

      <div className="grid gap-4">
        {modelTierData.map((tier, index) => (
          <div
            key={index}
            className="border border-[#D3E9FF] rounded-lg overflow-hidden"
          >
            <div
              className="bg-[#F0F7FF] p-4 flex justify-between items-center cursor-pointer"
              onClick={() =>
                setExpandedTier(expandedTier === index ? null : index)
              }
            >
              <div className="flex items-center">
                <span className="text-[#1975D2] font-bold text-lg">
                  {tier.credits}{" "}
                  {tier.credits === 1 ? "Credit per Use" : "Credits per Use"}
                </span>
              </div>
              {expandedTier === index ? (
                <ExpandLessIcon className="text-[#1975D2]" />
              ) : (
                <ExpandMoreIcon className="text-[#1975D2]" />
              )}
            </div>

            {expandedTier === index && (
              <div className="p-4 bg-white">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {tier.models.map((model, modelIndex) => (
                    <div
                      key={modelIndex}
                      className="bg-[#F9FCFF] p-3 rounded-lg border border-[#D3E9FF]"
                    >
                      <div className="font-medium text-[#1975D2]">
                        {model.provider}
                      </div>
                      <div className="text-[#3E3E3E]">{model.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <p className="text-sm text-gray-600 mt-4">
        *Credit usage rates are subject to change. Each use of a model deducts
        the specified number of credits from your wallet.
      </p>
    </div>
  );
};

const Pricing = () => {
  const [expanded, setExpanded] = useState(false);
  const [pricingContent, setPricingContent] = useState({
    Basic: {
      price: "Pay As You Go",
      subtitle: "Start comparing AI models instantly",
      features: [
        {
          icon: CompareIcon,
          text: "Compare text responses from different AI models side-by-side",
        },
        {
          icon: ImageIcon,
          text: "Upload and analyze images using advanced AI vision capabilities",
        },
        {
          icon: AutoAwesomeIcon,
          text: "Continue your chat with any AI model, individually or in parallel",
        },
        { icon: BoltIcon, text: "Access to multiple AI models" },
        {
          icon: AccountBalanceWalletIcon,
          text: "Easily add credits to your wallet",
        },
        { icon: CreditCardIcon, text: "No credit card required for sign up" },
        { icon: ShareIcon, text: "Share your AI conversations publicly" },
        {
          icon: ThumbUpOffAltIcon,
          text: "Vote for a response as helpful or not helpful",
        },
      ],
      cta: "Get Started",
      isSelected: true,
    },
    Advanced: {
      price: "Coming Soon",
      subtitle: "Enhanced features for power users",
      features: [
        {
          icon: BoltIcon,
          text: "Priority access to latest AI models and more...",
        },
      ],
      cta: "",
      isSelected: false,
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    amplitude.track("FAQ - " + panel + " - " + isExpanded);
  };

  const handleClick = () => {
    amplitude.track("Click - Get Started Now");
  };

  return (
    <Box className="sm:px-8 px-4 py-10">
      <Helmet>
        <title>
          Pricing & Plans | thisorthis.ai - AI Model Comparison Platform
        </title>
        <meta
          name="description"
          content="Explore flexible pricing options for thisorthis.ai, the leading side-by-side AI model comparison platform. Find the perfect plan to suit your needs and budget."
        />
      </Helmet>

      <h2 className="text-2xl text-[#3E3E3E] font-semibold">Pricing & Plans</h2>
      <div className="flex flex-col items-center mt-2">
        <PricingExplanation />
        <BonusCreditsAlert />
      </div>
      <Divider
        className="my-4 mt-2 border-[#B8DBFF] border-b-2 opacity-30 w-[50%] mx-auto"
        component="div"
      />
      <div className="flex justify-around mt-2 w-full">
        <div className="pricing-scroll-container custom-scrollbar">
          {Object.entries(pricingContent).map(([tier, content], i) => (
            <div
              key={`pricing-tier-${i}`}
              className={`flex flex-col py-6 px-8 border border-solid border-[#D3E9FF] rounded-lg sm:w-[350px] max-lg:w-[90%] shrink-0 ${
                content.isSelected ? "bg-[#1975D2]" : "bg-white"
              }`}
            >
              <h3
                className={`text-2xl font-bold ${
                  content.isSelected ? "text-white" : "text-[#1975D2]"
                }`}
              >
                {tier} Plan
              </h3>
              <h4
                className={`text-3xl font-bold my-4 ${
                  content.isSelected ? "text-white" : "text-[#3E3E3E]"
                }`}
              >
                {content.price}
              </h4>
              <p
                className={`text-xl mb-6 ${
                  content.isSelected ? "text-white" : "text-[#3E3E3E]"
                }`}
              >
                {content.subtitle}
              </p>
              <div className="space-y-4 mb-8">
                {content.features.map((feature, index) => (
                  <div key={index} className="flex items-center">
                    <feature.icon
                      className={`${
                        content.isSelected ? "text-white" : "text-[#1975D2]"
                      } mr-3`}
                      style={{ fontSize: 20 }}
                    />
                    <p
                      className={`${
                        content.isSelected ? "text-white" : "text-[#3E3E3E]"
                      } text-sm`}
                    >
                      {feature.text}
                    </p>
                  </div>
                ))}
              </div>
              {content.cta && (
                <NavLink to="/">
                  <Button
                    className={`mt-auto font-semibold text-lg py-3 w-full ${
                      content.isSelected
                        ? "bg-white text-[#1975d2]"
                        : "bg-[#2095f3] text-white"
                    } hover:bg-[#2095f3] hover:text-white transition-colors duration-300`}
                    onClick={handleClick}
                  >
                    {content.cta}
                  </Button>
                </NavLink>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Usage Section */}
      <div className="border border-solid border-[#CAE4FF] rounded-lg sm:p-8 p-4 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
        <h2 className="text-2xl text-[#3E3E3E] font-semibold">Usage</h2>
        <p className="sm:text-xl text-lg text-[##3E3E3E] font-normal mt-4">
          Users can top up their wallet balance with credits and use any AI
          model based on their needs. Credits make it simple to use any model
          without worrying about complex token calculations.
        </p>
      </div>

      {/* Key Points */}
      <div className="border border-solid border-[#CAE4FF] rounded-lg sm:p-8 p-4 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
        <h2 className="text-2xl text-[#3E3E3E] font-semibold">Key Points</h2>
        <ul className="sm:text-xl text-lg text-[##3E3E3E] font-normal mt-4">
          <li>No subscription or membership plans are required</li>
          <li>
            Users have the flexibility to use any model without any commitments
          </li>
          <li>
            Wallet balance can be topped up at any time, providing seamless
            access to AI models whenever needed
          </li>
        </ul>
      </div>

      {/* Model Tiers */}
      <ModelTiers />

      {/* FAQ Section */}
      <div className="border border-solid border-[#CAE4FF] rounded-lg sm:p-8 p-4 mt-12 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
        <h2 className="text-2xl text-[#3E3E3E] font-semibold">
          Frequently Asked Questions (FAQs)
        </h2>
        <div className="mt-4">
          <div>
            {faqData.map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`)}
                className={`rounded-xl ${index === 0 ? "mt-0" : "mt-4"} ${
                  index === faqData.length - 1 ? "mb-0" : "mb-4"
                }`}
                component={Box}
                disableGutters
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}bh-content`}
                  id={`panel${index + 1}bh-header`}
                  className={`bg-[#D3E9FF] border-0 py-2 ${
                    index === 0 ? "rounded-tl-xl rounded-tr-xl" : ""
                  } ${
                    index === faqData.length - 1
                      ? "rounded-bl-xl rounded-br-xl"
                      : ""
                  }`}
                >
                  <h3 className="text-[#3E3E3E] text-base font-medium">
                    Q: {item.question}
                  </h3>
                </AccordionSummary>
                <AccordionDetails className="py-4 bg-[#F9FCFF]">
                  <p className="text-base font-normal text-[#3E3E3E]">
                    {item.answer}
                  </p>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        .pricing-scroll-container {
          display: flex;
          overflow-x: auto;
          gap: 24px;
          padding: 24px;
        }
        @media (max-width: 1024px) {
          .pricing-scroll-container {
            white-space: nowrap;
            overflow-x: auto;
          }
          .pricing-scroll-container > div {
            display: inline-block;
            white-space: normal;
          }
        }
      `}</style>
    </Box>
  );
};

export default Pricing;
