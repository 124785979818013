import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WarningIcon from "@mui/icons-material/Warning";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { LoadingButton } from "@mui/lab";
import * as amplitude from "@amplitude/analytics-browser";

const DisableAccount = ({ handleDisableUser, isDisabled }) => {
  const [showFirstDialog, setShowFirstDialog] = useState(false);
  const [showSecondDialog, setShowSecondDialog] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const handleInitialDisable = () => {
    setShowFirstDialog(true);
    amplitude.track("Disable Account - Click");
  };

  const handleFirstConfirm = () => {
    setShowFirstDialog(false);
    setShowSecondDialog(true);
    amplitude.track("Disable Account - Confirm");
  };

  const handleSecondConfirm = () => {
    if (confirmText.toLowerCase() === "disable") {
      handleAccountDisable();
      amplitude.track("Disable Account - Final Confirm");
    }
  };

  const handleAccountDisable = () => {
    // Function to actually disable the account
    console.log("Account disabled");
    handleDisableUser();
    // setShowSecondDialog(false);
    setConfirmText("");
  };

  const handleCancel = () => {
    setShowFirstDialog(false);
    setShowSecondDialog(false);
    setConfirmText("");
    amplitude.track("Disable Account - Cancel");
  };

  return (
    <Box sx={{ p: 3, mt: 2 }}>
      {/* Disable Account Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="text"
          sx={{
            color: "text.disabled",
            textTransform: "none",
            fontSize: "0.875rem",
          }}
          onClick={handleInitialDisable}
        >
          Disable Account
        </Button>
      </Box>

      {/* First Confirmation Dialog */}
      <Dialog
        open={showFirstDialog}
        onClose={() => setShowFirstDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h3">Disable Account?</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will disable your account along with all your
            activities. Once disabled, you will no longer be able to retrieve
            your account data.
            <br />
            <br /> <b>Are you sure you want to continue?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "flex-start" }}>
          <Button
            onClick={handleFirstConfirm}
            sx={{
              color: "text.primary",
              bgcolor: "transparent",
              border: "1px solid",
              borderColor: "divider",
              "&:hover": {
                bgcolor: "action.hover",
              },
              mr: 1,
            }}
          >
            Yes, Continue
          </Button>
          <Button
            onClick={handleCancel}
            variant="contained"
            sx={{
              bgcolor: "error.main",
              color: "white",
              "&:hover": {
                bgcolor: "error.dark",
              },
            }}
          >
            No, Keep My Account
          </Button>
        </DialogActions>
      </Dialog>

      {/* Second Confirmation Dialog */}
      <Dialog
        open={showSecondDialog}
        onClose={() => setShowSecondDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h3">Final Confirmation Required</Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontWeight: 500,
                mb: 2,
              }}
            >
              <WarningIcon />
              Warning: This action cannot be undone!
            </Typography>

            <Typography variant="body1" sx={{ mb: 1 }}>
              Disabling your account will:
            </Typography>

            <List dense>
              {[
                "Remove access to all your account features",
                "Disable your profile and all associated activities",
                "Cancel all active subscriptions",
                "Archive all your past AI comparisons",
                "All of your unused credits and wallet top ups will be lost",
              ].map((text, index) => (
                <ListItem key={index} sx={{ py: 0 }}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              To confirm, please type "<b>disable</b>" in the box below:
            </Typography>

            <TextField
              fullWidth
              size="small"
              placeholder="Type 'disable' to confirm"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              sx={{ mt: 1 }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "flex-start" }}>
          <LoadingButton
            onClick={handleSecondConfirm}
            loading={isDisabled}
            disabled={confirmText.toLowerCase() !== "disable"}
            sx={{
              color: "text.primary",
              bgcolor: "transparent",
              border: "1px solid",
              borderColor: "divider",
              "&:hover": {
                bgcolor: "action.hover",
              },
              mr: 1,
            }}
          >
            Disable Now
          </LoadingButton>
          <Button
            onClick={handleCancel}
            variant="contained"
            sx={{
              bgcolor: "error.main",
              color: "white",
              "&:hover": {
                bgcolor: "error.dark",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DisableAccount;
