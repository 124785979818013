import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { AppBar, Box } from "@mui/material";
import Header from "./Header";
import { Outlet, useOutletContext } from "react-router";
import { useTheme } from "@mui/material/styles";
import Footer from "layout/Footer";

const MainLayout = () => {
  const theme = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarStateChange = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <div className="w-full flex flex-col min-h-screen">
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ zIndex: 1000 }}
      >
        <Header isSidebarOpen={isSidebarOpen} />
      </AppBar>
      <div className="flex flex-1 pt-[70px]">
        <div className="max-lg:hidden">
          <Sidebar onStateChange={handleSidebarStateChange} />
        </div>
        <Box
          className={`bg-white transition-all duration-300 ease-in-out ${
            isSidebarOpen ? "ml-[261px] w-[calc(100%-261px)] " : "w-full"
          } min-h-screen max-lg:w-full max-lg:ml-0`}
          sx={{ position: "relative" }}
        >
          <Outlet context={{ isSidebarOpen }} />
          <Footer />
        </Box>
      </div>
    </div>
  );
};

export default MainLayout;

export function useIsSidebarOpen() {
  return useOutletContext();
}
