import { lazy } from "react";
import { Navigate } from "react-router-dom";

// project imports
import Loadable from "ui-component/Loadable";
import DashboardHomePage from "views/DashboardHome/DashboardHomePage";
import MainLayout from "layout/MainLayout_new/MainLayout";
import Pricing from "views/pricing";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ProtectedRoute from "utils/ProtectedRoute";
import PublicResponse from "views/responses/PublicResponse";
import PublicLayout from "layout/PublicLayout";
import AuthComponent from "views/authentication/AuthComponent";
import HowItWorks from "views/how-it-works";
import Privacy from "views/privacy";
import Terms from "views/terms";
import MyProfile from "views/profile/Profile";
import DetailedUseCases from "views/AIExplorer/DetailedUseCases";
// import MainLayout from "layout/MainLayout";

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);

// response page routing
const ViewResponse = Loadable(lazy(() => import("views/responses")));

// shortID page routing
const ShortID = Loadable(lazy(() => import("views/shortID")));

// My Transaction list page routing
const Prompts = Loadable(lazy(() => import("views/prompts")));

// My Balance page loading
const Balance = Loadable(lazy(() => import("views/balance")));

// Load Feeback page
const Feedback = Loadable(lazy(() => import("views/feedback")));

//404 Page
const NotFound = Loadable(lazy(() => import("views/notfound")));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: "/",
  element: (
    <ProtectedRoute
      AuthenticatedComponent={MainLayout}
      PublicComponent={PublicLayout}
      layout={true}
    />
  ),
  children: [
    {
      path: "/",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={DashboardHomePage}
          PublicComponent={AuthComponent}
          layout={true}
        />
      ),
    },
    {
      path: "/compare",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={DashboardHomePage}
          PublicComponent={AuthComponent}
          layout={true}
        />
      ),
    },
    {
      path: "/*",
      element: <NotFound />,
    },
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "/s/:shortId",
      element: <ShortID />,
    },
    {
      path: "/prompts",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={Prompts}
          PublicComponent={AuthComponent}
          layout={true}
        />
      ),
    },
    {
      path: "/balance",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={Balance}
          PublicComponent={AuthComponent}
          layout={true}
        />
      ),
    },
    {
      path: "/profile",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={MyProfile}
          PublicComponent={AuthComponent}
          layout={true}
        />
      ),
    },
    {
      path: "/feedback",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={Feedback}
          PublicComponent={AuthComponent}
          layout={true}
        />
      ),
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/ai-explorer",
      element: <DetailedUseCases />,
    },
    {
      path: "view/:promptId",
      element: (
        <ProtectedRoute
          AuthenticatedComponent={ViewResponse}
          PublicComponent={PublicResponse}
          layout={true}
        />
      ),
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/how-it-works",
      element: <HowItWorks />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms-of-service",
      element: <Terms />,
    },
    {
      path: "/privacy-policy",
      element: <Privacy />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
  ],
};

export default MainRoutes;
