import React from "react";
import useHistory from "./useHistory";
import { IconButton, Box, Button, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Skeleton from "@mui/material/Skeleton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeletePromptDialog from "components/DeletePrompt";

const History = () => {
  const {
    loading,
    allPrompts,
    promptId,
    handleClick,
    truncateText,
    viewMore,
    viewAllPrompts,
  } = useHistory();

  // Add safety check for allPrompts
  const promptsToRender = Array.isArray(allPrompts?.[0]) ? allPrompts[0] : [];

  return (
    <>
      {loading ? (
        <Box className="mt-4">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((elm) => (
            <Skeleton
              key={`skeleton-${elm}`}
              className="bg-[#1A3A5A] mt-2 w-full"
              variant="rounded"
              height={40}
            />
          ))}
        </Box>
      ) : !promptsToRender.length ? (
        <Typography
          variant="body1"
          sx={{
            marginTop: "2em",
            textAlign: "center",
            color: "white",
            fontWeight: "semi-bold",
          }}
        >
          No prompts have been compared yet
        </Typography>
      ) : (
        <>
          <Box className="mt-2 pr-1 pl-1">
            {promptsToRender.map((prompt, index) => {
              // Add safety check for prompt object
              if (!prompt || !prompt.id || !prompt.title) return null;

              return (
                <div
                  key={`prompt-${prompt.id}-${index}`}
                  className={`group relative p-2 cursor-pointer rounded-lg hover:bg-[#11477e] ${
                    promptId === prompt.id ? "bg-[#11477E]" : "bg-transparent"
                  }`}
                  onClick={() => handleClick(prompt.id)} // Move onClick here
                >
                  <div className="flex justify-between items-center">
                    <div className="flex-grow min-w-0">
                      <Typography
                        className="text-white"
                        variant="body1"
                        style={{
                          fontSize:
                            promptId === prompt.id ? "0.85rem" : "0.8rem",
                          marginBottom: 0,
                        }}
                      >
                        {typeof truncateText === "function"
                          ? truncateText(prompt.title, 100)
                          : prompt.title}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </Box>
          {viewMore && (
            <div className="flex justify-end mt-4">
              <Button
                className="text-white mr-4 text-sm"
                onClick={viewAllPrompts}
                endIcon={<ArrowForwardIcon />}
              >
                View All
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default History;
