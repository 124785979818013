// project imports
import config from "config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isOpen: [], // for active default menu
  defaultId: "default",
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  balanceUpdated: false,
  updatePromptsMenu: [],
  promptsList: [],
  currentBalance: 0,
  modelDetails: [],
  userType: null,
  allModels: [],
  isRemembered: false,
  selModels: [],
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.UPDATE_BALANCE:
      return {
        ...state,
        balanceUpdated: !state.balanceUpdated,
      };
    case actionTypes.UPDATE_HISTORY:
      return {
        ...state,
        updatePromptsMenu: action.updatePromptsMenu,
      };
    case actionTypes.MY_BALANCE:
      return {
        ...state,
        currentBalance: action.currentBalance,
      };
    case actionTypes.MODEL_DETAILS:
      return {
        ...state,
        modelDetails: action.modelDetails,
      };
    case actionTypes.ALL_PROMPTS:
      return {
        ...state,
        promptsList: action.promptsList,
      };
    case actionTypes.USER_TYPE:
      return {
        ...state,
        userType: action.userType,
      };
    case actionTypes.IS_REMEMBERED:
      return {
        ...state,
        isRemembered: action.isRemembered,
      };
    case actionTypes.SELECTED_TYPES:
      return {
        ...state,
        selModels: action.selModels,
      };
    case actionTypes.ALL_MODELS:
      return {
        ...state,
        allModels: action.allModels,
      };
    default:
      return state;
  }
};

export default customizationReducer;
