import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";

const Footer = () => {
  return (
    <div className="footer-container w-full bg-[#FDFBFB] flex justify-between md:px-10 px-8 max-[548px]:flex-col items-center">
      <p className="text-[#222222] text-xs font-normal text-center">
        ⓒ 2024 Nisq Technologies Inc., All Rights Reserved.
      </p>
      <div className="flex items-center max-[548px]:flex-col max-[548px] mt-2">
        <div className="order-1 max-[548px]:order-1 max-[548px]:mb-2 ">
          <a
            href="https://www.producthunt.com/posts/thisorthis-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-thisorthis&#0045;ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=466200&theme=light"
              alt="thisorthis&#0046;ai - Compare&#0032;AI&#0032;models&#0032;side&#0045;by&#0045;side | Product Hunt"
              style={{ width: 200 }}
            />
          </a>
        </div>
        <div className="order-1 max-[548px]:order-1 max-[548px]:mb-2 ml-2">
          <a
            href="https://x.com/@thisorthis_ai"
            target="_blank"
            rel="noopener noreferrer"
            alt="@thisorthis_ai | X"
          >
            <img
              src="https://s3.amazonaws.com/thisorthis.ai-public/logos/x-logo.png"
              alt="thisorthis&#0046;ai - Compare&#0032;AI&#0032;models&#0032;side&#0045;by&#0045;side | X "
              style={{ width: 20, height: 20 }}
            />
          </a>
        </div>
        <div className="flex items-center order-2 max-[548px]:order-2">
          <Link
            className="text-[#222222] text-xs font-normal ml-2"
            to="/privacy"
          >
            Privacy
          </Link>

          <Link
            className="text-[#222222] text-xs font-normal ml-2"
            to="/terms-of-service"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
