import { Grid, Divider } from "@mui/material";

const SharePrompt = ({ title, responses, url, text }) => {
  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="500px"
      spacing={0}
    >
      <div>
        <img src="/images/white_logo.svg" alt="" />
      </div>
      <Divider
        sx={{ width: "100%", borderWidth: 1, borderColor: "#D8EBFF" }}
        className="mt-4"
      />
      {/* <div className="w-full flex justify-end">
        <Tooltip title={tooltipText}>
          <IconButton onClick={handleCopy}>
            {isCopied ? (
              <MdFileCopy className="text-[#1975D2] w-4 h-4" />
            ) : (
              <MdOutlineFileCopy className="text-[#1975D2] w-4 h-4" />
            )}
          </IconButton>
        </Tooltip>
      </div> */}

      <h4 className="sm:text-base text-sm font-normal text-[#030C15] mt-4">
        I prompted &nbsp;
        <span className="text-[#004E9C] font-medium">
          "{truncateText(title, 100)}"
        </span>
        &nbsp; to multiple AI models.
        <br />
        Compare all responses and vote now for the best AI model response
        at&nbsp;
        <span className="text-[#004E9C] font-medium">{url}</span>
      </h4>
    </Grid>
  );
};

export default SharePrompt;
