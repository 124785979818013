import { View, useAuthenticator } from "@aws-amplify/ui-react";
import { Typography, Button } from "@mui/material";
import { IconHexagon1Filled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import useInAppBrowserDetector from "./InAppBrowser";
import { isIOS } from "react-device-detect";

export const components = {
  SignIn: {
    Header() {
      const { toSignUp } = useAuthenticator();
      const { isInApp } = useInAppBrowserDetector();

      return (
        <>
          <h3 className="text-[#151D33] sm:text-3xl text-xl font-semibold">
            Welcome
          </h3>
          <p className="mt-1 text-[#4A5B84]">
            Don't have an account yet?
            <Button className="text-[#1975D2] text-left" onClick={toSignUp}>
              Sign up today for free
            </Button>{" "}
          </p>
          {isInApp && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Heads up!</strong>
              <span className="block sm:inline">
                {" "}
                Google Sign-In doesn't work in in-app browsers. For the best
                experience, please open this site in your default browser by{" "}
                {!isIOS ? (
                  <>
                    clicking{" "}
                    <a href="intent://thisorthis.ai#Intent;scheme=https;package=com.android.chrome;end">
                      here
                    </a>
                    .
                  </>
                ) : (
                  'tapping ••• on the top right side → "Open in Safari" / "Open in Browser" 🌐'
                )}
              </span>
            </div>
          )}
        </>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();
      return (
        <View textAlign="right">
          <Button className="text-[#1975D2]" onClick={toForgotPassword}>
            Forgot Password?
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      return (
        <>
          <h3 className="text-[#151D33]  sm:text-3xl text-xl font-semibold">
            Let's Get Started!
          </h3>
          <p className="mt-1 text-[#4A5B84] text-lg">No Credit Card required</p>
        </>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Typography variant="subtitle2">
            By creating an account, you acknowledge and accept
            <br />
            the <Link to="/terms-of-service">Terms of Service</Link> and{" "}
            <Link to="/privacy">Privacy Policy</Link> of thisorthis.ai.
          </Typography>
          <p className="text-[#4A5B84]">
            Already have an account?
            <Button className="text-[#1975D2]" onClick={toSignIn}>
              Sign in here
            </Button>{" "}
          </p>
        </View>
      );
    },
  },
  ForgotPassword: {
    Header() {
      return (
        <>
          <h3 className="text-[#151D33] sm:text-3xl text-xl font-semibold">
            Forgot your Password?
          </h3>
          <h5 className="text-[#4A5B84] font-normal sm:text-xl text-lg mt-2 mb-4">
            Please enter your registered email address to reset your password
          </h5>
        </>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <>
          <h3 className="text-[#151D33] text-3xl font-semibold">
            Password reset
          </h3>
          <h5 className="text-[#4A5B84] font-normal text-xl mt-2 mb-4">
            Your new password should be different from your old password
          </h5>
        </>
      );
    },
    Footer() {
      const { toSignIn, resendCode } = useAuthenticator();

      return (
        <div className="w-full flex items-center justify-between">
          <Button className="text-[#1975D2]" onClick={resendCode}>
            Resend Code
          </Button>

          <p className="text-[#4A5B84]">
            Back to
            <Button className="text-[#1975D2]" onClick={toSignIn}>
              Sign In
            </Button>
          </p>
        </div>
      );
    },
  },
};

export const formFields = {
  signIn: {
    username: {
      placeholder: "",
    },
    password: {
      placeholder: "",
    },
  },
  signUp: {
    password: {
      placeholder: "",
    },
    confirm_password: {
      placeholder: "",
    },
    email: {
      placeholder: "",
    },
    given_name: {
      placeholder: "",
    },
    family_name: {
      placeholder: "",
    },
  },
  forgotPassword: {
    username: {
      placeholder: "Enter your registered email",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "",
    },
    confirm_password: {
      placeholder: "",
    },
    password: {
      placeholder: "",
    },
  },
};
