import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";

export async function getIdToken() {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken;
  } catch (error) {
    console.log("Error fetching user attributes :", error);
    return null;
  }
}

export async function getUserAttributes() {
  try {
    const userAttributes = await fetchUserAttributes();
    return {
      userSerial: userAttributes["custom:user_serial"],
      sub: userAttributes.sub,
      email: userAttributes.email,
    };
  } catch (error) {
    console.log("Error fetching user attributes :", error);
    return null;
  }
}
