import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getIdToken, getUserAttributes } from "utils/verification";
import { useDispatch, useStore } from "react-redux";
import { ALL_PROMPTS } from "store/actions";
import * as amplitude from "@amplitude/analytics-browser";

const DeletePromptDialog = ({ promptId, title, open, onClose, isDisabled }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const store = useStore();
  const dispatch = useDispatch();

  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true);
      await handleDelete(promptId);
      setSnackbarOpen(true);
      deletePromptfromMenu(promptId);
      setTimeout(() => {
        onClose();
        isDisabled();
        setIsDeleting(false);
      }, 2000);
      amplitude.track("Prompt Deleted Successfully", {
        promptId: promptId,
      });
    } catch (error) {
      console.error("Error deleting prompt:", error);
      setIsDeleting(false);
      amplitude.track("Error Deleting Prompt", {
        promptId: promptId,
      });
      // You might want to add error handling UI here
    }
  };

  const handleDelete = async (promptId) => {
    // Implement your delete logic here
    console.log("Deleting prompt:", promptId);
    const idToken = await getIdToken();
    const { userSerial } = await getUserAttributes();

    const body = {
      x_user_ser: userSerial,
      promptId: promptId,
      isDelete: true,
    };

    const configHeaders = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/user/updateTitle",
        body,
        configHeaders
      );
      console.log("Deleted Prompt");
    } catch (error) {
      console.log("Error in updating title");
      console.log(error.message);
      throw error;
    }
  };

  const deletePromptfromMenu = (promptId) => {
    // Get current prompts using your existing selector
    const promptList = store.getState().customization.promptsList;
    console.log("Updating new title in menu list");
    // Create updated list and dispatch
    const updatedList = promptList.filter((prompt) => prompt.id !== promptId);
    dispatch({
      type: ALL_PROMPTS,
      promptsList: updatedList,
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="delete-prompt-dialog-title"
        aria-describedby="delete-prompt-dialog-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "550px", // Consistent width
            p: 2, // Add padding around all content
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          {" "}
          {/* Reduce bottom padding */}
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.75rem",
              fontWeight: 600,
              color: "text.primary",
              mb: 0,
            }}
          >
            Confirm Deletion
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ pt: 2 }}>
          {" "}
          {/* Adjust top padding */}
          <DialogContentText>
            <Typography
              variant="h3"
              sx={{
                fontSize: "1.25rem",
                color: "text.secondary",
                fontWeight: 500,
                fontStyle: "italic",
                mb: 3, // Add space before warning
              }}
            >
              "{title}"
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "1.1rem",
                color: "text.secondary",
                mb: 2,
                lineHeight: 1.5,
              }}
            >
              You're about to delete this prompt comparison permanently. Are you
              sure you want to proceed?
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "error.main",
                fontSize: "0.95rem",
                fontWeight: 500,
                mt: 1,
                opacity: 0.9, // Slightly soften the error color
              }}
            >
              All associated data and responses will be permanently removed and
              cannot be recovered.
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          sx={{
            px: 3,
            pb: 3,
            pt: 2,
            gap: 2, // Space between buttons
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              color: "text.secondary",
              borderColor: "grey.300",
              "&:hover": {
                borderColor: "grey.400",
                backgroundColor: "grey.50",
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleConfirmDelete}
            loading={isDeleting}
            variant="contained"
            sx={{
              bgcolor: "error.main",
              "&:hover": {
                bgcolor: "error.dark",
              },
            }}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          Prompt deleted successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default DeletePromptDialog;
