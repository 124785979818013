import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import * as amplitude from "@amplitude/analytics-browser";

export default function ErrorDialog() {
  const [open, setOpen] = React.useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const errorDescription = searchParams.get("error_description");

  React.useEffect(() => {
    if (errorDescription?.includes("User is not enabled")) {
      setOpen(true);
      amplitude.track("User is not enabled - Dialog");
    }
  }, [errorDescription]);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle id="error-dialog-title">
        <Alert severity="error">
          <AlertTitle>
            <Typography variant="h3">Account Disabled</Typography>
          </AlertTitle>
        </Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">
          Your account is currently disabled. Please contact support at
          contact@thisorthis.ai to restore access.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
