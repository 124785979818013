import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Link,
  Card,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Subscribe = () => {
  return (
    <>
      <h3 className="text-2xl text-black font-semibold mb-4 mt-8">
        Subscribe to Promptology
      </h3>
      <Card
        sx={{
          boxShadow: 1,
          padding: "2em",
        }}
      >
        <Typography variant="body1" sx={{ fontSize: "1rem" }}>
          Stay updated with the latest prompt engineering and design techniques,
          news, and tools in AI. Join our weekly newsletter!
        </Typography>
        <Link
          href="https://promptology.thisorthis.ai"
          target="_blank"
          rel="noopener noreferrer"
          display="flex"
          alignItems="center"
          mb={2}
          mt={1}
        >
          Visit Promptology <OpenInNewIcon fontSize="small" sx={{ ml: 0.5 }} />
        </Link>
        <iframe
          src="https://embeds.beehiiv.com/8bd1ebe5-3f2f-43ba-a7d5-abb3a409c16e?slim=true"
          data-test-id="beehiiv-embed"
          height="52"
          frameborder="0"
          scrolling="no"
          style={{
            margin: 0,
            borderRadius: "0px",
            backgroundColor: "transparent",
          }}
        ></iframe>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Note: Users who sign up to thisorthis.ai are automatically subscribed
          to this newsletter.
        </Typography>
      </Card>
    </>
  );
};

export default Subscribe;
