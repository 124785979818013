import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box, Paper, CircularProgress } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

const NotPublic = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      navigate("/");
    }
  }, [countdown]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.default",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            maxWidth: 400,
            width: "100%",
            textAlign: "center",
            animation: "fadeIn 0.5s ease-out",
            "@keyframes fadeIn": {
              from: { opacity: 0, transform: "translateY(20px)" },
              to: { opacity: 1, transform: "translateY(0)" },
            },
          }}
        >
          <LockIcon
            sx={{
              fontSize: 80,
              color: "error.main",
              mb: 2,
              animation: "scaleIn 0.5s ease-out 0.2s both",
              "@keyframes scaleIn": {
                from: { transform: "scale(0)" },
                to: { transform: "scale(1)" },
              },
            }}
          />
          <Typography variant="h4" component="h1" gutterBottom color="error">
            Access Denied
          </Typography>
          <Typography variant="body1" paragraph>
            You do not have permission to view this link, or the author has not
            made the prompt response public.
          </Typography>
          <Typography variant="body2" gutterBottom fontSize="1.5rem">
            Redirecting you to the home page in {countdown} seconds...
          </Typography>
          <CircularProgress />
        </Paper>
      </Box>
      <Grid item textAlign="center"></Grid>
    </Grid>
  );
};

export default NotPublic;
