import { Box, Button, Divider } from "@mui/material";
import BalanceSection from "layout/MainLayout/Header/BalanceSection";
import ProfileSection from "layout/MainLayout/Header/ProfileSection";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import ProfileMenu from "layout/MainLayout/Header/ProfileSection/ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import PerfectScrollbar from "react-perfect-scrollbar";
import History from "views/prompts-history/History";
import * as amplitude from "@amplitude/analytics-browser";

const Header = ({ isSidebarOpen }) => {
  const tab768 = useMediaQuery("(max-width:768px)");
  const mob559 = useMediaQuery("(max-width:559px)");
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
    amplitude.track("Open History Drawer");
  };
  const navigate = useNavigate();
  const onNewCompareClick = () => {
    amplitude.track("Click New Comparison Button");
    navigate("/");
  };
  return (
    <Box
      className="flex justify-between w-full fixed top-0 left-0 bg-white"
      sx={{
        boxShadow: "0 2px 4px -1px rgba(0,0,0,0.06)",
        height: "56px",
      }}
    >
      <div
        className={`${
          isSidebarOpen ? "bg-[#0A2F54]" : "bg-white"
        } transition-colors duration-300 max-lg:hidden`}
      >
        <Link to="/">
          <div className="px-12 py-4 w-[261px]">
            <img
              src={
                isSidebarOpen
                  ? "/images/dark_logo.svg"
                  : "/images/white_logo.svg"
              }
              alt="thisorthis.ai"
              className="h-5"
            />
          </div>
        </Link>
      </div>

      <div className="lg:pr-6 sm:px-4 sm:py-2 p-2 flex justify-between w-full items-center max-lg:shadow-md">
        <div className="flex items-center">
          <IconButton
            aria-label="menu"
            className="mr-2 lg:hidden"
            onClick={toggleDrawer(true)}
            size="small"
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box className="lg:ml-3 ml-0">
            {mob559 ? (
              <></>
            ) : tab768 ? (
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "#1975D2",
                  },
                  padding: "4px",
                }}
                onClick={onNewCompareClick}
                size="small"
                className="bg-[#1975D2] text-white"
                aria-label="add comparison"
              >
                <AddIcon fontSize="small" />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon fontSize="small" />}
                onClick={onNewCompareClick}
                disableElevation
                sx={{
                  borderRadius: 3,
                  backgroundColor: "#1975D2",
                  padding: "4px 12px",
                  fontSize: "0.875rem",
                }}
              >
                New AI Comparison
              </Button>
            )}
          </Box>
        </div>

        <Link className="lg:hidden" to="/">
          <div>
            <img src="/images/white_logo.svg" alt="" className="h-6" />
          </div>
        </Link>

        <Box className="flex items-center">
          <NavLink
            to="/ai-explorer"
            className={({ isActive }) =>
              `text-base mr-6 ${
                isActive ? "font-semibold text-[#1975D2]" : "text-[#030C15]"
              } no-underline max-lg:hidden`
            }
          >
            AI Explorer
          </NavLink>
          <NavLink
            to="/how-it-works"
            className={({ isActive }) =>
              `text-base mr-6 ${
                isActive ? "font-semibold text-[#1975D2]" : "text-[#030C15]"
              } no-underline max-lg:hidden`
            }
          >
            How it works?
          </NavLink>
          <NavLink
            to="/pricing"
            className={({ isActive }) =>
              `text-base mr-6 ${
                isActive ? "font-semibold text-[#1975D2]" : "text-[#030C15]"
              } no-underline max-lg:hidden`
            }
          >
            Pricing
          </NavLink>

          {tab768 ? <></> : <BalanceSection />}
          <ProfileMenu />
        </Box>
      </div>

      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box
          style={{
            width: mob559 ? 250 : 350,
            height: "100%",
            backgroundColor: "#0A2F54",
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <div className="w-full h-full flex flex-col justify-end px-4 pb-8 pt-14">
            {" "}
            {/* Reduced from pt-[85px] */}
            {mob559 ? (
              <Button
                variant="contained"
                size="small" // Changed from large to small
                startIcon={<AddIcon />}
                onClick={onNewCompareClick}
                disableElevation
                sx={{
                  borderRadius: 5, // Changed from 5 to match header
                  backgroundColor: "#1975D2",
                  padding: "4px 12px",
                  fontSize: "0.875rem",
                }}
              >
                New AI Comparison
              </Button>
            ) : (
              <></>
            )}
            <Button
              disableFocusRipple
              disableTouchRipple
              disableRipple
              className="text-white font-semibold w-full text-base bg-[#1A3A5A] max-[559px]:mt-4" // Changed from text-xl and adjusted margin
            >
              History
            </Button>
            <PerfectScrollbar>
              <History />
            </PerfectScrollbar>
          </div>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
