// amplify import
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import cognito_config from "./aws-cognito";

import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import * as serviceWorker from "serviceWorker";
import App from "App";
import { store } from "store";

// style + assets
import "assets/scss/style.scss";

// style + assets
import "assets/scss/style.scss";
import config from "./config";
import React from "react";
Amplify.configure(cognito_config);
//console.log("GA ID is" + process.env.REACT_APP_GA_ID);
// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (process.env.REACT_APP_ENV !== "dev") {
  console.log = function () {};
  console.error = function () {};
  console.debug = function () {};
  console.info = function () {};
  console.warn = function () {};
}

root.render(
  <Authenticator.Provider>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </Provider>
  </Authenticator.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
