import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import remarkGfm from "remark-gfm";
import { formFields, components } from "views/authentication/custom_public";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Button,
  Box,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import useAskPromptHook from "../DashboardHome/useAskPromptHook";
import { ReactComponent as ThisWithoutBg } from "../../assets/images/01.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MdOutlineFileCopy } from "react-icons/md";
import { MdFileCopy } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import * as amplitude from "@amplitude/analytics-browser";

const PublicCardResponse = ({
  modelName,
  subResponse,
  responseId,
  promptId,
  voteCount,
  modelId,
}) => {
  const [logoUrl, setLogoUrl] = useState("");
  const [countThis, setCountThis] = useState(0);
  const [countUp, setCountUp] = useState(0);
  const [voteThis, setVoteThis] = useState(false);
  const [voteUp, setVoteUp] = useState(false);
  const [voteDown, setVoteDown] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [copiedStates, setCopiedStates] = useState({});

  useEffect(() => {
    setCountThis(parseInt(voteCount.this));
    setCountUp(parseInt(voteCount.up));
  }, [voteCount]);

  useEffect(() => {
    const model_logos = {
      "AI21 Labs":
        "https://s3.amazonaws.com/thisorthis.ai-public/logos/ai21.png",
      Amazon: "https://s3.amazonaws.com/thisorthis.ai-public/logos/amazon.png",
      Cohere: "https://s3.amazonaws.com/thisorthis.ai-public/logos/cohere.png",
      Google: "https://s3.amazonaws.com/thisorthis.ai-public/logos/google.png",
      Meta: "https://s3.amazonaws.com/thisorthis.ai-public/logos/meta.png",
      "Mistral AI":
        "https://s3.amazonaws.com/thisorthis.ai-public/logos/mistral.png",
      OpenAI: "https://s3.amazonaws.com/thisorthis.ai-public/logos/openai.png",
      Anthropic:
        "https://s3.amazonaws.com/thisorthis.ai-public/logos/anthropic.png",
    };
    switch (modelName) {
      case "Titan Text G1 - Express":
      case "Titan Text G1 - Lite":
      case "Nova Micro":
      case "Nova Lite":
      case "Nova Pro":
        setLogoUrl(model_logos["Amazon"]);
        break;
      case "Jurassic-2 Mid":
      case "Jurassic-2 Ultra":
      case "Jamba Instruct":
      case "Jamba 1.5 Mini":
      case "Jamba 1.5 Large":
        setLogoUrl(model_logos["AI21 Labs"]);
        break;
      case "Command":
      case "Command Light":
        setLogoUrl(model_logos["Cohere"]);
        break;
      case "Gemini 1.0 Pro":
      case "Gemini 1.5 Flash":
      case "Gemini 1.5 Pro":
        setLogoUrl(model_logos["Google"]);
        break;
      case "Llama 3 70B Instruct":
      case "Llama 3 8B Instruct":
      case "Llama 3.2 1B Instruct":
      case "Llama 3.2 3B Instruct":
        setLogoUrl(model_logos["Meta"]);
        break;
      case "Mistral 7B Instruct":
      case "Mistral 8X7B Instruct":
        setLogoUrl(model_logos["Mistral AI"]);
        break;
      case "GPT 3.5 Turbo":
      case "GPT 4":
      case "GPT 4 Turbo":
      case "GPT 4o":
      case "GPT 4o mini":
      case "GPT o1 mini":
        setLogoUrl(model_logos["OpenAI"]);
        break;
      case "Claude 2.1":
      case "Claude 3.5 Sonnet":
      case "Claude Instant":
      case "Claude 3.5 Haiku":
        setLogoUrl(model_logos["Anthropic"]);
        break;
      default:
        break;
    }
    console.log(logoUrl);
  }, []);

  useEffect(() => {
    setExpandedItems({});
  }, [responseId]);

  const handleCopy = useCallback(
    (content, index) => {
      navigator.clipboard.writeText(content);
      setCopiedStates((prev) => ({ ...prev, [index]: true }));
      setTooltipText("Copied");
      amplitude.track("Click Public Copy Response " + index, {
        promptId: promptId,
        responseId: responseId,
      });
      setTimeout(() => {
        setCopiedStates((prev) => ({ ...prev, [index]: false }));
        setTooltipText("Copy");
      }, 2000);
    },
    [promptId, responseId]
  );

  const toggleExpand = useCallback((index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);

  const onVoteClick = () => {
    setIsModalOpen(true);
    amplitude.track("Click Public Vote");
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const renderMarkdown = useMemo(
    () => (content) => (
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm]}
        components={{
          table: ({ node, ...props }) => (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginBottom: "1em",
                marginTop: "1em",
                border: "1px solid #1975D2",
              }}
              {...props}
            />
          ),
          th: ({ node, ...props }) => (
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#D3E9FF",
                textAlign: "left",
                color: "#030C15",
                fontWeight: "600",
              }}
              {...props}
            />
          ),
          td: ({ node, ...props }) => (
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                color: "#030C15",
                fontWeight: "400",
                backgroundColor: "#fff",
              }}
              {...props}
            />
          ),
          p: ({ node, ...props }) => (
            <p style={{ marginTop: "1em" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li
              style={{
                marginLeft: "30px",
                marginTop: "0.5em",
              }}
              {...props}
            >
              {props.children}
            </li>
          ),
          h1: ({ node, ...props }) => (
            <h1 style={{ marginTop: "1em", lineHeight: "1.2" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ marginTop: "1em", lineHeight: "1" }} {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 style={{ marginTop: "1em" }} {...props} />
          ),
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={11.5}
                  style={{
                    marginTop: 0,
                    backgroundColor: "#2f2f2f",
                  }}
                >
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                    style={materialDark}
                    {...props}
                  />
                </Grid>
              </Grid>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    ),
    [tooltipText]
  );

  const renderSubResponse = useCallback(
    (subResponseItem, index) => {
      if (subResponseItem.M.text) {
        const content = subResponseItem.M.text.S;
        const type = subResponseItem.M.type.S;
        const isImage = subResponseItem.M.isImagePresent?.BOOL ?? false;
        const imageData = subResponseItem.M.imageData?.L ?? null;
        const isExpanded = expandedItems[index] || false;
        const isCopied = copiedStates[index] || false;

        return (
          <div key={`${index}-asdqwe`}>
            {(type === "prompt" || type === "e.prompt") && (
              <div className="relative">
                <Typography
                  variant="body1"
                  className={`md:text-[14px] text-[12px] text-[#030C15] font-normal m-2 ml-[30px] mt-6 mb-0 shadow-md hover:shadow-lg transition-shadow duration-300 ${
                    isExpanded ? "" : "line-clamp-2"
                  }`}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                    padding: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transform: "translateY(-2px)",
                  }}
                >
                  {content}
                </Typography>
                {content.length > 100 && (
                  <div
                    style={{
                      textAlign: "right",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      onClick={() => toggleExpand(index)}
                      className="text-blue-500 hover:text-blue-700 mt-0 mr-0 text-[12px]"
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      {isExpanded ? "Less" : "More"}
                    </Button>
                  </div>
                )}
                {isImage && imageData && (
                  <div className="flex flex-row space-x-4 overflow-x-auto p-4 ml-4">
                    {imageData.map((item, index) => (
                      <img
                        key={item.M.imageId.S}
                        src={item.M.thumbnailUrl.S}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          height: "80px",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}{" "}
            {type === "response" && (
              <div className="relative">
                <Typography
                  variant="body1"
                  className="md:text-[16px] text-[14px] text-[#030C15] font-normal m-2 ml-[30px] mt-4"
                >
                  {renderMarkdown(content)}
                </Typography>
                <div className="flex justify-end mt-2 mb-4">
                  <Divider
                    sx={{
                      width: "30%",
                      borderColor: "#DFDDDD",
                    }}
                    className="ml-[2rem] flex-grow"
                  />
                </div>
              </div>
            )}{" "}
            {type === "error" && (
              <Typography
                variant="body1"
                className="md:text-[14px] text-[12px] text-[#030C15] font-normal m-2 ml-[30px] mt-4 mb-4 text-red-500 bg-red-100 p-2 rounded"
              >
                {content}
              </Typography>
            )}
          </div>
        );
      }
      return null;
    },
    [expandedItems, toggleExpand, renderMarkdown, copiedStates, handleCopy]
  );

  const memoizedSubResponses = useMemo(() => {
    return subResponse?.map(renderSubResponse) || [];
  }, [subResponse, renderSubResponse]);

  return (
    <>
      {subResponse ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "0px" }}
        >
          <Card
            variant="outlined"
            elevation={0}
            sx={{
              boxShadow: "0 0px 10px rgba(0, 0, 0, 0.1)",
              border: "none",
              backgroundColor: "#fff",
              width: "auto",
              borderRadius: "2em",
              padding: "0px",
            }}
            style={{ height: "805px", width: "100%" }}
          >
            <CardContent style={{ width: "auto", height: "100%" }}>
              <Grid container alignItems="center">
                <Grid item xs={13}>
                  <div className="flex flex-col items-center gap-4 justify-between sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%]">
                    <div className="gap-[16px] flex items-center mt-[5px] lg:ml-[-10px] xl:ml-0 w-auto text-nowrap">
                      <Avatar
                        className="bg-transparent lg:w-12 h-12"
                        alt={modelName}
                        src={logoUrl}
                      />
                      <Typography className="text-[#030C15] text-[16px] md:text-[22px] font-semibold ">
                        {modelName}
                      </Typography>
                    </div>
                    <Divider
                      sx={{
                        width: "30%",
                        borderWidth: 1,
                        borderColor: "#DFDDDD",
                      }}
                      className="ml-[2rem]"
                    />
                    <div className="flex justify-between lg:w-[100%] xl:w-[100%] md:w-[100%] sm:w-[100%] w-[100%] ">
                      {modelId !== null && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "70px",
                            height: "25px",
                            position: "relative",
                          }}
                        >
                          <Tooltip title="Mark this as the best response">
                            <Button
                              onClick={onVoteClick}
                              disableElevation
                              style={{
                                height: "23px",
                                minWidth: "45px",
                                padding: 0,
                                border: "none",
                                background: "none",
                              }}
                            >
                              <ThisWithoutBg className="normal-this-button" />
                            </Button>
                          </Tooltip>
                          {countThis > 0 && (
                            <Typography
                              variant="body2"
                              style={{
                                color: "#1975D2",
                                fontSize: "12px",
                                fontWeight: "600",
                                borderColor: "#1975D2",
                                border: "1px solid #1975D2",
                                borderLeft: "none",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                paddingTop: "2px",
                                width: "25px",
                                height: "23px",
                                borderTopRightRadius: "4px",
                                borderBottomRightRadius: "4px",
                                textAlign: "center",
                                position: "absolute",
                                right: "2px",
                              }}
                            >
                              {countThis}
                            </Typography>
                          )}
                        </div>
                      )}
                      <div className="flex items-center gap-[10px]">
                        <div
                          style={{
                            border: "1px solid #1975D2",
                            borderRadius: "5px",
                            height: "25px",
                          }}
                          className="flex items-center lg:mr-0 border border-[#1975D2] rounded-[5px] w-auto "
                        >
                          <Grid>
                            <Tooltip title="Helpful">
                              <IconButton
                                style={{ padding: "0", marginLeft: "4px" }}
                                onClick={() => onVoteClick("up")}
                              >
                                <ThumbUpOffAltIcon
                                  className="h-[18px] w-[18px] mb-2 mt-2"
                                  style={{ color: voteUp ? "green" : "" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              marginLeft: "4px",
                            }}
                          >
                            {countUp > 0 && (
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#686B6D",
                                  fontSize: "14px",
                                  marginRight: "2px",
                                  fontWeight: "600",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {countUp}
                              </Typography>
                            )}
                            <span style={{ marginBottom: "2px" }}>|</span>
                          </Grid>
                          <Grid item>
                            {countUp < 0 && (
                              <Typography
                                variant="body2"
                                style={{
                                  color: "#686B6D",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                  fontWeight: "600",
                                  marginBottom: "8px",
                                  marginTop: "8px",
                                }}
                              >
                                {countUp}
                              </Typography>
                            )}
                          </Grid>
                          <Grid>
                            <Tooltip title="Not helpful">
                              <IconButton
                                style={{
                                  padding: "0",
                                  marginLeft: "4px",
                                  marginRight: "8px",
                                }}
                                onClick={onVoteClick}
                              >
                                <ThumbDownOffAltIcon
                                  style={{
                                    color: voteDown ? "red" : "",
                                    width: "18px",
                                    height: "18px",
                                    marginBottom: "8px",
                                    marginTop: "8px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </div>
                        <Grid
                          item
                          style={{
                            padding: "0",
                          }}
                        >
                          <Tooltip title="Copy All">
                            <IconButton onClick={() => onVoteClick()}>
                              <ContentCopyOutlinedIcon
                                style={{
                                  fontSize: "large",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                style={{ height: "550px" }}
                className="mt-4 rounded-[5px]"
              >
                <div style={{ borderRadius: "8px", height: "auto" }}>
                  <div
                    className="custom-scrollbar"
                    style={{
                      color: "#1975D2",
                      height: "627px",
                      backgroundColor: "#F2F8FE",
                      borderRadius: "8px",
                      overflow: "auto",
                      display: "flex",
                      flexDirection:
                        subResponse.length > 6 ? "column-reverse" : "column",
                    }}
                  >
                    {subResponse.length > 0 && (
                      <div style={{ marginTop: "20px" }}>
                        {memoizedSubResponses}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Grid item>
          <Skeleton
            variant="rectangular"
            className="w-full h-full rounded-3xl"
          />
        </Grid>
      )}
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="signin-modal"
        aria-describedby="signin-modal-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: "transparent", // Makes the dialog background transparent
            boxShadow: "none", // Removes the box shadow
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            height: "90vh", // Ensures the parent box takes the full height of the viewport
            overflowY: "auto",
          }}
        >
          <Authenticator formFields={formFields} components={components} />
          <IconButton onClick={handleClose} className="mt-0">
            <CloseIcon
              variant="contained"
              fontSize="medium"
              sx={{ color: "white" }}
            />
            <Typography variant="body2" sx={{ color: "white" }}>
              Close
            </Typography>
          </IconButton>
        </Box>
      </Dialog>
    </>
  );
};

export default PublicCardResponse;
