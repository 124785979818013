import { Grid, Paper, useMediaQuery, Card } from "@mui/material";
import React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import useFetchGraphValues from "./useFetchGraphValues";
import Skeleton from "@mui/material/Skeleton";
import { ResponsiveChartContainer } from "@mui/x-charts";

const AIModelsGraph = () => {
  // const theme = useTheme();
  const matches = useMediaQuery("(min-width:768px) and (max-width: 1423px)");
  const { seriesData, loading, allResponses, allPrompts } =
    useFetchGraphValues();
  //for graph
  const desktop1478 = useMediaQuery("(max-width:1478px)");
  const desktop1330 = useMediaQuery("(max-width:1330px)");
  const tab768 = useMediaQuery("(max-width:768px)");
  const mob558 = useMediaQuery("(max-width:558px");
  const mob418 = useMediaQuery("(max-width:418px)");
  const mob380 = useMediaQuery("(max-width:380px)");
  return (
    <div className="mt-8">
      {loading ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <div className="w-full flex items-center md:flex-row flex-col">
            <Skeleton variant="circular" width={200} height={200} />
            <div className="ml-8 md:mt-8">
              <Skeleton
                variant="rectangular"
                width={210}
                height={20}
                className="mt-2"
              />
              <Skeleton
                variant="rectangular"
                width={210}
                height={20}
                className="mt-2"
              />
              <Skeleton
                variant="rectangular"
                width={210}
                height={20}
                className="mt-2"
              />
              <Skeleton
                variant="rectangular"
                width={210}
                height={20}
                className="mt-2"
              />
              <Skeleton
                variant="rectangular"
                width={210}
                height={20}
                className="mt-2"
              />
            </div>
            <div className="ml-8 flex items-center max-md:hidden">
              <Skeleton variant="circular" width={150} height={150} />
              <Skeleton
                variant="circular"
                width={150}
                height={150}
                className="ml-4"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <h3 className="text-2xl text-black font-semibold mb-4">
            Top AI Models used globally
          </h3>
          <Card
            sx={{
              boxShadow: 1,
              padding: "2em",
            }}
          >
            <div
              className={`w-full flex justify-between ${matches ? "flex-col" : "md:flex-row"} flex-col`}
            >
              <div className={`w-full ${matches ? "w-[90%]" : "md:w-[60%]"} `}>
                <PieChart
                  colors={[
                    "#2B68D2",
                    "#3D79E1",
                    "#5089ED",
                    "#6794E2",
                    "#92B8F8",
                  ]}
                  series={[
                    {
                      arcLabel: (item) => `${item.value + "%"}`,
                      arcLabelMinAngle: 25,
                      data: seriesData,
                      cx: mob558 ? 100 : matches ? "30%" : "40%",
                      cy: mob558 ? 100 : "50%",
                      paddingAngle: 0,
                      outerRadius: mob380
                        ? 75
                        : mob418
                          ? 80
                          : tab768
                            ? 90
                            : desktop1330
                              ? 80
                              : desktop1478
                                ? 90
                                : 100,
                    },
                  ]}
                  height={mob558 ? 350 : 200}
                  slotProps={{
                    legend: {
                      direction: mob558 ? "row" : "column",
                      position: {
                        vertical: mob558 ? "bottom" : "middle",
                        horizontal: mob558 ? "middle" : "right",
                      },
                      padding: 0,
                    },
                  }}
                  // width={503}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: "#fff",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                    },
                    "& .MuiPieArc-root": {
                      stroke: "transparent",
                      strokeWidth: "0",
                    },
                  }}
                />
              </div>

              <div
                className={`flex justify-around  max-[320px]:flex-col ${matches ? "w-full mt-8" : " md:w-[40%] md:mt-0 mt-4"} `}
              >
                <div className="max-[320px]:flex max-[320px]:flex-col max-[320px]:items-center">
                  <div className="sm:w-32 w-28 sm:h-32 h-28 bg-[#B8D7F5] rounded-full flex items-center justify-center">
                    <div className="sm:w-24 w-20 sm:h-24 h-20 bg-white rounded-full flex items-center justify-center max-[320px]:mx-auto">
                      <h4 className="sm:text-2xl text-lg text-[#004488] font-medium">
                        {allPrompts}
                      </h4>
                    </div>
                  </div>
                  <h3 className="sm:text-base text-sm font-medium text-[#004488] text-center mt-4">
                    Prompts <br /> Compared{" "}
                  </h3>
                </div>
                <div className=" ml-4 max-[320px]:mt-4 max-[320px]:ml-0 max-[320px]:flex max-[374px]:flex-col max-[320px]:items-center">
                  <div className="sm:w-32 w-28 sm:h-32 h-28 bg-[#B8D7F5] rounded-full flex items-center justify-center">
                    <div className="sm:w-24 w-20 sm:h-24 h-20 bg-white rounded-full flex items-center justify-center">
                      <h4 className="sm:text-2xl text-lg text-[#004488] font-medium">
                        {allResponses}
                      </h4>
                    </div>
                  </div>
                  <h3 className="sm:text-base text-sm font-medium text-[#004488] text-center mt-4">
                    AI responses <br /> Generated{" "}
                  </h3>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default AIModelsGraph;
