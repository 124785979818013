// material-ui
import { useEffect, useState, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Tooltip,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  Skeleton,
  Divider,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import IosShareIcon from "@mui/icons-material/IosShare";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Helmet } from "react-helmet";
import * as amplitude from "@amplitude/analytics-browser";
import {
  Diversity1,
  ViewModule,
  ViewStream,
  ViewWeek,
} from "@mui/icons-material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import PublicDisplayResponse from "./PublicDisplayResponse";
import NotPublic from "views/isNotPublic";

//Import Share Prompt UI component
import SharePrompt from "ui-component/share/SharePrompt";
import { MdOutlineFileCopy, MdFileCopy } from "react-icons/md";

const PublicResponse = () => {
  let { promptId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [promptText, setPromptText] = useState("");
  const [responseIDs, setResponseIDs] = useState([]);
  const [subList, setSubList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shareTipText, setSharetipText] = useState("Copy URL");
  const [open, setOpen] = useState(false);
  const [copyText, setCopyText] = useState(null);
  const [copyURL, SetCopyURL] = useState(null);
  const [responseDate, setResponseDate] = useState("");
  const [responseType, setResponseType] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy");
  const [view, setView] = useState("grid2");
  const [totalIds, setTotalIds] = useState(0);
  const [isPublic, setIsPublic] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleFlag, setTitleFlag] = useState(null);
  const mob492 = useMediaQuery("(max-width:492px)");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const prompt = params.get("prompt");
    const source = axios.CancelToken.source();

    async function getAIResponses() {
      try {
        //Get ID Token and User Ser

        let p_type = "o";
        if (prompt && prompt === "new") {
          p_type = "n";
        }

        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/user/responseIDs",
          {
            params: {
              promptId: promptId,
              p_type: p_type,
            },
            cancelToken: source.token,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const { isDisabled } = response.data;

          if (isDisabled) {
            console.log("Prompt has been deleted");
            navigate("/not-found");
            return true;
          }

          const pText = response.data.promptText;
          const rIds = response.data.responseIds;
          const sList = response.data.subList;
          const resDate = response.data.createdAt;
          const resType = response.data.responseType;
          const publicFlag = response.data.isPublic;

          console.log(rIds);

          if (!publicFlag) {
            setIsPublic(publicFlag);
          } else {
            setPromptText(pText);
            setResponseIDs(rIds);
            setSubList(sList);
            setTotalIds(rIds.length);
            setResponseDate(resDate);
            setResponseType(resType);
            setIsPublic(publicFlag);
            setTitle(response.data.title);
            if (!response.data.titleFlag) {
              setTitleFlag(false);
            } else {
              setTitleFlag(true);
            }

            //Set Copy Text and Link
            const base_url = window.location.origin;
            const text = `I prompted "${truncateText(pText, 100)}" to multiple AI models! \nCompare all responses and vote ⬆️ ⬇️ now for the best AI model response at https://thisorthis.ai/s/${response.data.shortId}`;
            const shareLink = base_url + `/s/${response.data.shortId}`;
            setCopyText(text);
            SetCopyURL(shareLink);

            setLoading(false);
          }
        }

        // Get Prompt Text and ResponseIDs array.

        // Based on array length, return components and start individual GET requests
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.status === 404) {
          console.log("404 not found");
          navigate("/not-found"); // Redirect to 404 page
        } else {
          // Handle other errors
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          }
          console.error("An error occurred:", error);
        }
      }
    }

    if (prompt && prompt === "new") {
      //Await 3 seconds
      setTimeout(() => {
        getAIResponses();
      }, 5000);
    } else {
      getAIResponses();
    }
    return () => {
      source.cancel("Operation canceled due to new request.");
    };
  }, [promptId]);

  useEffect(() => {
    // Fetch ResponseId for each modelId from subList
    if (subList.length !== 0) {
      subList.forEach(checkForResponseId);
    }
    // Add that to mrIds
  }, [subList, promptId]);

  const checkForResponseId = useCallback(
    async (modelId) => {
      console.log("Fetching for id ", modelId);
      const source = axios.CancelToken.source();

      const fetchData = async () => {
        console.log("Getting delayed response for ", modelId);
        const result = await axios.get(
          process.env.REACT_APP_BASE_URL + "/responses/delayed",
          {
            params: {
              promptId: promptId,
              modelId: modelId,
            },
            cancelToken: source.token,
          }
        );
        return result;
      };

      try {
        //console.log(result.data.responseText)
        const result = await exponentialBackoff(fetchData);
        const rId = result.data.responseId;

        setResponseIDs((prevItems) => [
          ...prevItems,
          { responseId: rId, modelId: modelId },
        ]);
      } catch (err) {
        console.error(`Failed to fetch DELAYED response for ${modelId}`, err);
      }
    },
    [promptId]
  );

  const exponentialBackoff = async (
    fn,
    maxRetries = 10,
    initialDelay = 1000
  ) => {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        const result = await fn();
        if (result.status === 200) {
          return result; // Stop and return on 200 status
        }
        // If status is not 200, we'll retry
        throw new Error(`Received non-200 status: ${result.status}`);
      } catch (error) {
        retries++;
        if (retries >= maxRetries) throw error;
        // Check if the error has a response and if it's a 200
        if (error.response && error.response.status === 200) {
          return error.response; // Stop and return on 200 status, even if axios threw an error
        }

        const delay = Math.min(initialDelay * Math.pow(2, retries), 5000); // Max delay of 5 seconds
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  useEffect(() => {
    if (mob492) {
      setView("grid1");
    }
  }, []);

  const changeView = (type) => {
    setView(type);

    let viewType = "grid";
    if (type === "grid1") {
      viewType = "vertical";
    } else if (type === "row") {
      viewType = "horizontal";
    }
    amplitude.track("Toggle View - " + viewType);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const shareNow = () => {
    setOpen(true);
    navigator.clipboard
      .writeText(copyURL)
      .then(() => {
        setSharetipText("Copied!");
        setTimeout(() => {
          setSharetipText("Copy URL");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleCopy = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setTooltipText("Copied!");
        setIsCopied(true);
        setTimeout(() => {
          setTooltipText("Copy");
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getGridProps = (viewType) => {
    switch (viewType) {
      case "grid2":
        return { xs: 12, sm: 12, md: 6, lg: totalIds === 2 ? 6 : 4 };
      case "grid1":
        return { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
      case "row":
        return { xs: 10, sm: 10, md: 8, lg: 8, xl: 8 };
      default:
        return { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
    }
  };

  async function shareAll() {
    try {
      if (navigator.share) {
        // Check if Web Share API is supported
        await navigator.share({
          title: "thisorthis.ai",
          text: copyText,
          // url: copyURL,
        });
        amplitude.track("Public Share Prompt", {
          promptId: promptId,
        });
      } else {
        // Fallback for browsers that do not support Web Share API
        navigator.clipboard.writeText(copyText);
        alert(
          "Direct sharing is not supported in this browser. The text has been copied."
        );
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  }

  return isPublic !== null && !isPublic ? (
    <NotPublic />
  ) : isPublic ? (
    <>
      <div className="w-[95%] mx-auto min-h-screen  pb-8">
        <Helmet>
          <title>
            {titleFlag
              ? title.slice(0, 45)
              : "Compare AI Responses - thisorthis.ai"}
          </title>

          <meta
            name="description"
            content="Compare AI responses generated via different AI models only at thisorthis.ai"
          />
        </Helmet>

        {loading &&
        (!promptText || responseIDs.length !== 0 || subList.length !== 0) ? (
          <div className="w-full p-8">
            <Skeleton
              variant="rectangular"
              className="w-full rounded-[2em]"
              height={60}
            />
          </div>
        ) : (
          promptText && (
            <>
              <div>
                <div className="flex items-center justify-between p-2 h-[48px] mb-4">
                  <div className="flex items-center gap-1">
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#686B6D",
                      }}
                    >
                      {responseDate}
                    </Typography>
                    <span style={{ marginBottom: "3px", color: "#686B6D" }}>
                      {" "}
                      |{" "}
                    </span>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#686B6D",
                      }}
                    >
                      {responseType}
                    </Typography>
                  </div>
                  <div className="flex shadow-sm border">
                    {!mob492 && (
                      <IconButton
                        onClick={() => changeView("grid2")}
                        sx={{
                          mr: 1,
                          color: view === "grid2" ? "#1975D2" : "default",
                        }}
                      >
                        <ViewModule fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => changeView("grid1")}
                      sx={{
                        mr: 1,
                        color: view === "grid1" ? "#1975D2" : "default",
                      }}
                    >
                      <ViewStream fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => changeView("row")}
                      sx={{
                        color: view === "row" ? "#1975D2" : "default",
                      }}
                    >
                      <ViewWeek fontSize="small" />
                    </IconButton>
                  </div>
                  <div className="flex shadow-sm border">
                    <IconButton onClick={shareNow}>
                      <ShareIcon style={{ color: "#1975D2" }} />
                    </IconButton>
                  </div>
                </div>

                <Box>
                  <Grid
                    container
                    spacing={4}
                    sx={
                      view === "row"
                        ? {
                            flexWrap: "nowrap",
                            overflowX: "auto",
                            "& > .MuiGrid-item": {
                              flexShrink: 0,
                              width: "auto",
                              maxWidth: "none",
                            },
                          }
                        : {}
                    }
                  >
                    {responseIDs.length !== 0 &&
                      responseIDs.map((id) => (
                        <Grid
                          item
                          key={id}
                          {...getGridProps(view)}
                          sx={{
                            mb: view === "row" ? 5 : 0,
                            mr: view === "row" ? 1 : 0,
                          }}
                        >
                          <PublicDisplayResponse
                            key={id}
                            responseId={id.responseId}
                            promptId={promptId}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </div>
            </>
          )
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          className="w-full"
        >
          <DialogContent>
            <Grid container justifyContent="center" padding={2}>
              <SharePrompt
                title={promptText}
                text={copyText}
                responses="Generated with thisorthis.ai"
                url={copyURL}
              />
            </Grid>
            <Divider flexItem className="border-[#BCDDFF] my-4" />
            <Grid container justifyContent="flex-end" xs={12} spacing={1}>
              <div className="w-full flex py-2 sm:px-4 items-center justify-between ">
                <div className="flex items-center">
                  <Tooltip title={shareTipText}>
                    <IconButton onClick={shareNow}>
                      <InsertLinkIcon className="text-[#1975D2]" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={tooltipText}>
                    <IconButton onClick={handleCopy}>
                      {isCopied ? (
                        <MdFileCopy className="text-[#1975D2] w-4 h-4" />
                      ) : (
                        <MdOutlineFileCopy className="text-[#1975D2] w-4 h-4" />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>

                <Button
                  className="bg-[#1975D2] rounded-[2em] py-2 px-4"
                  startIcon={<ShareIcon />}
                  onClick={shareAll}
                  variant="contained"
                  disableElevation
                >
                  Share Now
                </Button>
              </div>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  ) : (
    <></>
  );
};

export default PublicResponse;
