import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ALL_PROMPTS } from "store/actions";
import * as amplitude from "@amplitude/analytics-browser";

const useHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { promptId } = useParams();
  const [loading, setLoading] = useState(true);
  const [viewMore, setViewMore] = useState(false);
  const promptList = useSelector((state) => state.customization.promptsList);
  const customization = useSelector((state) => state.customization);
  const { updatePromptsMenu } = customization;
  const [allPrompts, setAllPrompts] = useState([promptList]);

  useEffect(() => {
    async function fetchPrompts() {
      setLoading(true);
      try {
        const result = await handleFetchUserAttributes();
        const idToken = await getIdToken();

        if (!result || !idToken) {
          console.error("Failed to get user attributes or ID token");
          setLoading(false);
          return;
        }

        // Get User Type and Model Details from a single GET API
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/user/menuPromptList",
          {
            params: {
              x_user_ser: result.userSerial,
            },
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Prompt List for Menu received from server - ");
        if (response.data.promptList.length === 0) {
          setAllPrompts([]);
          setLoading(false);
        } else {
          const menuPromptList = response.data.promptList.map((obj) => ({
            id: obj.promptId,
            title: obj.promptTextCut,
            type: "item",
            url: `/view/${obj.promptId}`,
            breadcrumbs: false,
          }));
          dispatch({ type: ALL_PROMPTS, promptsList: menuPromptList });
          setLoading(false);
          setViewMore(!!response.data.viewMore);
        }
      } catch (error) {
        console.log("Error fetching prompts");
        console.log(error);
      }
    }

    if (allPrompts?.[0]?.length === 0) {
      console.log("Fetching prompts");
      fetchPrompts();
    } else if (updatePromptsMenu.length > 0) {
      const new_list = [updatePromptsMenu[0], ...promptList];
      dispatch({ type: ALL_PROMPTS, promptsList: new_list });
    }
  }, [updatePromptsMenu]);

  useEffect(() => {
    console.log("Prompt List changed");
    // Only update if the new promptList is different from what's in allPrompts
    if (
      !allPrompts[0] ||
      JSON.stringify(allPrompts[0]) !== JSON.stringify(promptList)
    ) {
      setAllPrompts([promptList]);
    } else {
      console.log("Prompt List not changed");
      console.log(promptList);
    }
  }, [promptList]);

  const handleClick = (id) => {
    const route = "/view/" + id;
    // onSideClose();
    navigate(route);
  };

  const viewAllPrompts = () => {
    amplitude.track("Click - View All Prompts");
    navigate("/prompts");
  };

  const truncateText = (text, maxLength) => {
    // Add null/undefined check
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return {
    loading,
    allPrompts,
    promptId,
    handleClick,
    truncateText,
    viewMore,
    viewAllPrompts,
  };
};

async function getIdToken() {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken;
  } catch (error) {
    console.log("Error getting ID token:", error);
    return null;
  }
}

async function handleFetchUserAttributes() {
  try {
    const userAttributes = await fetchUserAttributes();
    return {
      userSerial: userAttributes["custom:user_serial"],
      sub: userAttributes.sub,
    };
  } catch (error) {
    console.log(error);
  }
}

export default useHistory;
