import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Checkbox,
  Button,
  Avatar,
  Grid,
  Divider,
  FormControlLabel,
  Snackbar,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  TrySharp,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { getIdToken, getUserAttributes } from "utils/verification";
import { LoadingButton } from "@mui/lab";
import DisableAccount from "./DisableProfile";
import * as amplitude from "@amplitude/analytics-browser";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router";

const MyProfile = () => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newFeatures, setNewFeatures] = useState(true);
  const [monthlySummary, setMonthlySummary] = useState(true);
  const [news, setNews] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [ifirstName, setIFirstName] = useState(null);
  const [ilastName, setILastName] = useState(null);
  const [inewFeatures, setINewFeatures] = useState(true);
  const [iMonthlySummary, setIMonthlySummary] = useState(true);
  const [iNews, setINews] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableAccount, setDisableAccount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setFirstName(null);
    setLastName(null);
    setLoading(false);
    setIsEditing(false);
    setDisableAccount(false);
    setIsDisabled(false);

    const fetchProfile = async () => {
      try {
        const { userSerial, sub, email } = await getUserAttributes();
        const idToken = await getIdToken();
        const source = axios.CancelToken.source();

        if (idToken) {
          const response = await axios.get(
            process.env.REACT_APP_BASE_URL + "/user/getProfile",
            {
              params: {
                x_user_ser: userSerial,
                sub_id: sub,
              },
              cancelToken: source.token,
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log(response.data);

          setUserEmail(email);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setNewFeatures(response.data.newFeatures);
          setMonthlySummary(response.data.monthlySummary);
          setNews(response.data.news);
          setIFirstName(response.data.firstName);
          setILastName(response.data.lastName);
          setINewFeatures(response.data.newFeatures);
          setIMonthlySummary(response.data.monthlySummary);
          setINews(response.data.news);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchProfile();
    amplitude.track("View - My Profile");

    //setEmail from fetchAttributes
  }, []);

  useEffect(() => {
    if (disableAccount) {
      setTimeout(() => {
        handleSignOut();
      }, 2000);
    }
  }, [disableAccount]);

  const handleSave = () => {
    setLoading(true);
    amplitude.track("Click - Save Profile");

    const saveProfile = async () => {
      try {
        const { userSerial, sub, email } = await getUserAttributes();
        const idToken = await getIdToken();

        if (idToken) {
          const configHeaders = {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          };

          const body = {
            firstName: firstName,
            lastName: lastName,
            newFeatures: newFeatures,
            userId: userSerial,
            sub: sub,
            email: email,
            news: news,
            monthlySummary: monthlySummary,
          };

          await axios.post(
            process.env.REACT_APP_BASE_URL + "/user/saveProfile",
            body,
            configHeaders
          );
          setIFirstName(firstName);
          setILastName(lastName);
          setINewFeatures(newFeatures);
          setIMonthlySummary(monthlySummary);
          setINews(news);
          setIsEditing(false);
          setSnackbarMessage("Profile updated successfully!");
          setLoading(false);
          setSnackbarOpen(true);
        }
      } catch (e) {
        console.log(e.message);
        setSnackbarMessage("Please try again!");
        setLoading(false);
        setSnackbarOpen(true);
      }
    };

    if (
      firstName !== ifirstName ||
      lastName !== ilastName ||
      newFeatures !== inewFeatures ||
      monthlySummary !== iMonthlySummary ||
      news !== iNews
    ) {
      saveProfile();
    } else {
      setIsEditing(false);
      setSnackbarMessage("No changes made.");
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleDisableUser = () => {
    setIsDisabled(true);
    amplitude.track("Disabling User");

    const deleteProfile = async () => {
      try {
        const { userSerial, sub, email } = await getUserAttributes();
        const idToken = await getIdToken();

        if (idToken) {
          const configHeaders = {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          };

          const body = {
            firstName: firstName,
            lastName: lastName,
            newFeatures: newFeatures,
            userId: userSerial,
            sub: sub,
            email: email,
            news: news,
            monthlySummary: monthlySummary,
            disableProfile: true,
          };

          await axios.post(
            process.env.REACT_APP_BASE_URL + "/user/saveProfile",
            body,
            configHeaders
          );
          setSnackbarMessage("Profile disabled successfully!");
          setIsDisabled(false);
          setSnackbarOpen(true);
          setDisableAccount(true);
          amplitude.track("User disabled and logged out");
        }
      } catch (e) {
        console.log(e.message);
        setSnackbarMessage("Please try again!");
        setLoading(false);
        setSnackbarOpen(true);
        setIsDisabled(false);
        amplitude.track("Disabling User Failed");
      }
    };

    deleteProfile();
  };

  async function handleSignOut() {
    try {
      await signOut();
      sessionStorage.clear();
      localStorage.clear();
      amplitude.track("User disabled and logged out");
      amplitude.reset();
      navigate("/");
    } catch (error) {
      signOut();
      amplitude.track("Log Out");
      amplitude.reset();
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  }

  const clearEdit = () => {
    setIsEditing(false);
    amplitude.track("Click - Clear Edit");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const clickEdit = () => {
    setIsEditing(true);
    amplitude.track("Click - Edit Profile");
  };

  return (
    <section className="w-[95%] mx-auto min-h-screen pb-8">
      <Helmet>
        <title>My Profile - thisorthis.ai</title>
        <meta
          name="description"
          content="Edit your profile and preferences - thisorthis.ai"
        />
      </Helmet>
      <Grid item xs={12} marginBottom={2}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ fontSize: "22px" }}
          marginLeft={2}
        >
          My Profile
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card sx={{ maxWidth: 600, width: "100%", boxShadow: 2 }}>
          <CardContent>
            {!firstName && !lastName ? (
              <Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="circular" width={100} height={100} />
                <Skeleton variant="rectangular" width="100%" height={100} />
                <Skeleton variant="rounded" width="100%" height={100} />
              </Stack>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Avatar
                    alt={`${firstName.charAt(0)}${lastName.charAt(0)}`}
                    sx={{
                      width: 68,
                      height: 68,
                      bgcolor: "#0D2751",
                      color: "#fff",
                      fontSize: "25px",
                    }}
                  >
                    {`${firstName.charAt(0)}${lastName.charAt(0)}`}
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem" }}
                    color="textSecondary"
                    align="center"
                  >
                    Manage your profile information and preferences
                  </Typography>
                </Grid>
                <Grid item xs={12} className="mt-2 mb-4">
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={!isEditing}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={!isEditing}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={userEmail}
                    disabled
                    variant="outlined"
                    helperText="Email cannot be changed"
                  />
                </Grid>
                <Grid item xs={12} className="mt-4">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem" }}
                    gutterBottom
                  >
                    Notification Preferences
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newFeatures}
                        onChange={(e) => setNewFeatures(e.target.checked)}
                        sx={{ color: "#1975D2" }}
                        disabled={!isEditing}
                      />
                    }
                    label="Stay informed about platform improvements and updates"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={news}
                        onChange={(e) => setNews(e.target.checked)}
                        sx={{ color: "#1975D2" }}
                        disabled={!isEditing}
                      />
                    }
                    label="Get notified about important announcements and news"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={monthlySummary}
                        onChange={(e) => setMonthlySummary(e.target.checked)}
                        sx={{ color: "#1975D2" }}
                        disabled={!isEditing}
                      />
                    }
                    label="Receive monthly summaries of your account activity"
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {isEditing ? (
                      <>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          loading={loading}
                          startIcon={<SaveIcon />}
                          onClick={handleSave}
                          sx={{
                            borderRadius: 5,
                            backgroundColor: "#1975D2",
                            padding: "1em",
                          }}
                        >
                          Save Profile
                        </LoadingButton>
                        {!loading && (
                          <IconButton
                            sx={{
                              position: "absolute",
                              right: "-48px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                            onClick={clearEdit}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={clickEdit}
                        sx={{
                          borderRadius: 5,
                          backgroundColor: "#1975D2",
                          padding: "1em",
                        }}
                      >
                        Edit Profile
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Grid>
      {firstName && lastName && (
        <DisableAccount
          isDisabled={isDisabled}
          handleDisableUser={handleDisableUser}
        />
      )}
    </section>
  );
};

export default MyProfile;
