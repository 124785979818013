import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Card,
  CardContent,
  IconButton,
  Button,
  Chip,
  useMediaQuery,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";

const MAIN_COLOR = "#1975D2";
const TEXT_COLOR = "#3E3E3E";

const PromptChip = ({ type }) => (
  <Chip
    label={type}
    size="small"
    sx={{
      bgcolor: MAIN_COLOR,
      color: "white",
      fontWeight: "bold",
      fontSize: "0.7rem",
      height: "24px",
    }}
  />
);

const AIExplorerPreview = () => {
  const [featuredGroups, setFeaturedGroups] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [userPrompt, setUserPrompt] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchFeaturedGroups = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/public/ai-prompt-library`
        );
        const allGroups = response.data;
        setFeaturedGroups(Object.entries(allGroups).slice(0, 7));
      } catch (error) {
        console.error("Error fetching featured groups:", error);
      }
    };

    fetchFeaturedGroups();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    amplitude.track("Click - Use Case Tab - " + newValue);
  };

  return (
    <Box className="mt-8">
      <h3 className="text-2xl text-black font-semibold mb-4">
        AI Explorer / Use Cases
      </h3>
      <Card
        sx={{
          boxShadow: 1,
          padding: "2em",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: "1rem" }}
          color={TEXT_COLOR}
          paragraph
        >
          Discover commonly used prompts for various professions. Compare top AI
          models, explore tailored prompts, and revolutionize your workflow.
        </Typography>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="featured user groups"
          sx={{ mb: 1 }}
        >
          {featuredGroups.map(([group], index) => (
            <Tab key={index} label={group} />
          ))}
        </Tabs>

        {featuredGroups[activeTab] && (
          <Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {featuredGroups[activeTab][1]
                .slice(0, 3)
                .map((useCase, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "calc(50% - 8px)",
                        md: "calc(33.33% - 8px)",
                      },
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          mb: 1,
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          {useCase.title}
                        </Typography>
                        <IconButton
                          size="small"
                          href={useCase.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Open use case link"
                        >
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 1, height: "60px", overflow: "auto" }}
                      >
                        {useCase.prompt}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
            </Box>
          </Box>
        )}

        <Box>
          <Button
            component={RouterLink}
            to="/ai-explorer"
            variant="contained"
            onClick={() => amplitude.track("Click - View More in AI Explorer")}
            size="large"
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: "4em",
              bgcolor: "#1975D2",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#1565C0",
              },
            }}
          >
            View More in AI Explorer
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default AIExplorerPreview;
