import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Container,
  Divider,
} from "@mui/material";

const TEXT_COLOR = "#3E3E3E";

const Demo = () => {
  return (
    <Container maxWidth="100%">
      <Grid container spacing={2} className="text-center">
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              title="Text-Based AI Interactions"
              sx={{ "& .MuiCardHeader-title": { fontWeight: "bold" } }}
            />
            <CardContent>
              <div
                style={{
                  position: "relative",
                  boxSizing: "content-box",
                  maxHeight: "80vh",
                  width: "100%",
                  aspectRatio: "1.935483870967742",
                  padding: "0 0 40px 0",
                }}
              >
                <iframe
                  src="https://app.supademo.com/embed/cm0wm8pr6020w12mfmj048iq3?embed_v=2"
                  loading="lazy"
                  title="thisorthis.ai"
                  allow="clipboard-write"
                  frameBorder="0" // Changed from frameborder
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true} // Changed from allowfullscreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <Typography variant="body1" color={TEXT_COLOR} sx={{ mt: 2 }}>
                Watch how different AI models respond to the same text prompt,
                allowing you to compare their responses, reasoning, and
                capabilities in real-time.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Vision-Enabled AI Analysis"
              sx={{ "& .MuiCardHeader-title": { fontWeight: "bold" } }}
            />
            <CardContent>
              <div
                style={{
                  position: "relative",
                  boxSizing: "content-box",
                  maxHeight: "80vh",
                  width: "100%",
                  aspectRatio: "1.935483870967742",
                  padding: "0 0 40px 0",
                }}
              >
                <iframe
                  src="https://app.supademo.com/embed/cm1rxmz500k44spgcfr8lwsjj?embed_v=2"
                  loading="lazy"
                  title="thisorthis.ai"
                  allow="clipboard-write"
                  frameBorder="0" // Changed from frameborder
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true} // Changed from allowfullscreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <Typography variant="body1" color={TEXT_COLOR} sx={{ mt: 2 }}>
                See how various AI models analyze and respond to the same image
                prompt, showcasing differences in visual recognition and
                interpretation across models.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Demo;
