import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Box,
  CircularProgress,
  ButtonGroup,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled, useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as amplitude from "@amplitude/analytics-browser";
import { Category } from "@mui/icons-material";
import { AmplifyError } from "@aws-amplify/core/internals/utils";

const MAIN_COLOR = "#1975D2";
const MAIN_COLOR_CONTRAST = "#FAFAFA";
const TEXT_COLOR = "#3E3E3E";
const thumbnail_url = "https://tot-thumbnails.s3.amazonaws.com/images/";

const ImageThumbnails = ({ images }) => (
  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
    {images.map((src, index) => (
      <img
        key={index}
        src={thumbnail_url + src}
        alt={`Image ${index + 1}`}
        style={{ height: 64, objectFit: "cover", borderRadius: 4 }}
      />
    ))}
  </Box>
);

const CopyablePrompt = ({ prompt, title }) => {
  const [tooltipTitle, setTooltipTitle] = useState("Copy");

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(prompt);
      setTooltipTitle("Copied!");
      setTimeout(() => setTooltipTitle("Copy"), 2000);
      amplitude.track("Copy Prompt", { Prompt: prompt, Category: title });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        bgcolor: (theme) => alpha(MAIN_COLOR, 0.1),
        p: 2,
        borderRadius: 2,
      }}
    >
      <Typography
        variant="subtitle2"
        gutterBottom
        color={TEXT_COLOR}
        fontWeight="bold"
      >
        Example Prompt:
      </Typography>
      <Typography variant="body1" fontSize="1.2rem" pr={4} color={TEXT_COLOR}>
        "{prompt}"
      </Typography>
      <Tooltip title={tooltipTitle} placement="top" arrow>
        <IconButton
          onClick={handleCopy}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: { TEXT_COLOR },
            "&:hover": {
              bgcolor: (theme) => alpha(TEXT_COLOR, 0.2),
            },
          }}
          size="small"
        >
          <ContentCopyIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

async function viewComparison(url, title) {
  amplitude.track("Click View Comparison", { URL: url, Category: title });
}

const UseCase = ({ useCase }) => (
  <Card
    elevation={3}
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      transition: "0.3s",
      "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: 6,
      },
    }}
  >
    <CardHeader
      title={useCase.title}
      subheader={useCase.description}
      sx={{
        bgcolor: MAIN_COLOR_CONTRAST,
        "& .MuiCardHeader-title": {
          fontSize: "1.25rem",
          fontWeight: "bold",
          color: TEXT_COLOR,
        },
        "& .MuiCardHeader-subheader": {
          color: TEXT_COLOR,
          fontSize: "1rem",
        },
      }}
    />
    <CardContent
      sx={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: 1 }}
    >
      <CopyablePrompt prompt={useCase.prompt} title={useCase.title} />
      {useCase.images && <ImageThumbnails images={useCase.images} />}
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{ mt: 2, fontSize: "1rem" }}
      >
        AI Models Compared:
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "1rem" }}>
        {useCase.models.join(", ")}
      </Typography>
    </CardContent>
    <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
      <Button
        size="small"
        variant="contained"
        href={useCase.url}
        onClick={() => viewComparison(useCase.url, useCase.title)}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          backgroundColor: MAIN_COLOR,
          color: "white",
          "&:hover": {
            backgroundColor: alpha(MAIN_COLOR, 0.8),
          },
          textTransform: "none",
          borderRadius: 2,
          py: 1,
          px: 2,
        }}
      >
        View this Comparison
      </Button>
    </CardActions>
  </Card>
);

const ScrollableButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  overflowX: "auto",
  whiteSpace: "nowrap",
  width: "100%",
  "-webkit-overflow-scrolling": "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  "& .MuiButtonGroup-grouped": {
    minWidth: "auto",
    flexShrink: 0,
    "&:not(:last-of-type)": {
      borderRight: "none", // Remove right border
    },
    "&:hover": {
      borderRight: "none", // Ensure no border appears on hover
    },
  },
}));

const StyledButton = styled(Button)(({ theme, isActive, isSmallScreen }) => ({
  justifyContent: "space-between",
  textAlign: "left",
  whiteSpace: "normal",
  borderRight: "none",
  borderRadius: theme.shape.borderRadius + "px !important",
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1, 2),
  color: "#000000",
  "&:hover": {
    borderRight: "none",
  },
  ...(isActive && {
    color: "#1975D2",
    fontWeight: "bold",
    ...(isSmallScreen && {
      borderBottom: "2px solid #1975D2",
    }),
  }),
}));

const DetailedUseCases = () => {
  const [activeGroup, setActiveGroup] = useState("");
  const [useCases, setUseCases] = useState({});
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Simulate API call with a setTimeout

    const getPromptLibrary = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/public/ai-prompt-library"
        );
        // console.log(response);

        setUseCases(response.data);
        setActiveGroup(Object.keys(response.data)[0]);
        setLoading(false);
      } catch (e) {
        console.log("Error fetching comparisons");
        console.log(e);
      }
    };

    getPromptLibrary();

    amplitude.track("View - AI Explorer");
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="sm:px-8 px-4 py-10">
      <Helmet>
        <title>
          AI Explorer | Compare AI Models for Professional Use Cases -
          thisorthis.ai
        </title>
        <meta
          name="description"
          content="Explore AI-powered solutions across multiple professions at thisorthis.ai. Compare responses from top AI models, access 100+ curated prompts, and enhance your workflow with our interactive AI Explorer tool."
        />
        <meta
          name="keywords"
          content="AI Explorer, AI comparisons, professional AI tools, GPT-4, Claude, Gemini Pro, AI prompts, thisorthis, prompt, library"
        />
      </Helmet>
      <h2 className="text-2xl  text-[#3E3E3E] font-semibold mb-2">
        AI Explorer
      </h2>
      <Typography variant="h4" color="#3E3E3E" gutterBottom>
        Explore AI Use Cases and Prompts by Profession
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          marginTop: 4,
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "275px" }, mb: { xs: 2, md: 0 } }}>
          <ScrollableButtonGroup
            variant="text"
            aria-label="use case groups"
            sx={{
              flexDirection: isSmallScreen ? "row" : "column",
              alignItems: "flex-start",
              width: { xs: "100%", md: "275px" },
              ...(isSmallScreen ? {} : { height: "100%" }),
            }}
          >
            {Object.keys(useCases).map((group) => (
              <StyledButton
                key={group}
                onClick={() => {
                  setActiveGroup(group);
                  amplitude.track("User Group - " + group);
                }}
                isActive={activeGroup === group}
                isSmallScreen={isSmallScreen}
                sx={{
                  ...(isSmallScreen ? {} : { width: "100%" }),
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem" }}
                  fontWeight={activeGroup === group ? "bold" : ""}
                >
                  {group}
                </Typography>
                {activeGroup === group && !isSmallScreen && (
                  <ArrowForwardIosIcon fontSize="small" sx={{ ml: 1 }} />
                )}
              </StyledButton>
            ))}
          </ScrollableButtonGroup>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            {useCases[activeGroup]?.map((useCase, index) => (
              <Grid item key={index} xs={12} md={6}>
                <UseCase useCase={useCase} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailedUseCases;
