import React from "react";
import { Card, Typography, Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const HomeIntro = () => {
  return (
    <Box className="mt-8">
      <h3 className="text-2xl text-black font-semibold mb-4">
        Unlock the Full Potential of AI Comparison
      </h3>
      <Card
        sx={{
          boxShadow: 1,
          padding: "2em",
        }}
      >
        <h3 className="text-xl font-medium text-[#004488] ">Text Insights</h3>{" "}
        <Typography variant="body1" sx={{ fontSize: "1.1rem", marginTop: 1 }}>
          Witness how different AI models interpret and respond to identical
          text prompts. Uncover nuances in language processing, reasoning, and
          creative expression across various AI models.
        </Typography>
        <h3 className="text-xl font-medium text-[#004488] mt-6 ">
          Visual Analysis
        </h3>{" "}
        <Typography variant="body1" sx={{ fontSize: "1.1rem", marginTop: 1 }}>
          From intricate artwork to complex academic diagrams and problems,
          explore diverse AI perspectives on any image. Enable students,
          artists, researchers, and professionals to gain multifaceted insights
          from visual data.
        </Typography>
        <Button
          component={RouterLink}
          to="/how-it-works"
          variant="contained"
          onClick={() => {
            amplitude.track("Click - Learn How it Works");
          }}
          size="large"
          sx={{
            mt: 4,
            mb: 2,
            borderRadius: "4em",
            bgcolor: "#1975D2",
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              bgcolor: "#1565C0",
            },
          }}
        >
          Learn How it Works?
        </Button>
      </Card>
    </Box>
  );
};

export default HomeIntro;
