import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Skeleton } from "@mui/material";
import PublicCardResponse from "./PublicCardResponse";

const PublicDisplayResponse = ({ responseId, promptId }) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [modelName, setModelName] = useState(null);
  const [voteCount, setVoteCount] = useState({});
  const [modelId, setModelId] = useState(0);
  const [subResponse, setSubResponse] = useState(null);

  useEffect(() => {
    let reqStatus = null;
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const fetchResponses = await axios.get(
          process.env.REACT_APP_BASE_URL + "/public/responses/all",
          {
            params: {
              promptId: promptId,
              responseId: responseId,
            },
            cancelToken: source.token,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setResponse(fetchResponses.data.responseText);
        setSubResponse(fetchResponses.data.subResponses);
        setModelName(fetchResponses.data.publicName);
        setModelId(fetchResponses.data.modelId);
        setVoteCount({
          this: fetchResponses.data.countThis,
          up: fetchResponses.data.countUp,
        });

        setLoading(false);
        reqStatus = 200;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          reqStatus = 500;
        } else {
          reqStatus = error.response ? error.response.status : "Network Error";
          console.error("There was an error!", reqStatus);
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          }
        }
      }
    };

    async function getDisplayReponse(retries = 10) {
      await fetchData();
      if (reqStatus === 500 && retries > 0) {
        let delay = Math.floor(Math.random() * (8000 - 1000 + 1)) + 1000; // Random delay between 1000 and 8000 ms
        await new Promise((resolve) => setTimeout(resolve, delay));
        return getDisplayReponse(retries - 1);
      }
      if (retries === 0) {
        setLoading(false); // Ensure loading is false when retries are exhausted
      }
    }

    getDisplayReponse();
    return () => {
      source.cancel("Operation canceled due to new request.");
    };
  }, [responseId]);

  return (
    <Grid item>
      {loading ? (
        <Grid item sx={12}>
          <Skeleton
            variant="rectangular"
            className="w-full h-full rounded-3xl"
            height={400}
          />
        </Grid>
      ) : (
        responseId !== null &&
        subResponse !== null && (
          <PublicCardResponse
            modelName={modelName}
            subResponse={subResponse}
            responseId={responseId}
            promptId={promptId}
            voteCount={voteCount}
            modelId={modelId}
          />
        )
      )}
    </Grid>
  );
};

export default PublicDisplayResponse;
