import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import History from "views/prompts-history/History";
import PushPinIcon from "@mui/icons-material/PushPin";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import * as amplitude from "@amplitude/analytics-browser";

const SIdebar = ({ onStateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(true);
  const sidebarRef = useRef(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleToggle = () => {
    setIsOpen(!isOpen);
    amplitude.track("Click - History Icon");
  };

  useEffect(() => {
    if (!isLargeScreen) {
      setIsOpen(false);
    }
  }, [isLargeScreen]);

  const handlePin = () => {
    setIsPinned(!isPinned);
    setIsOpen(true);
    amplitude.track("Pin Sidebar - " + !isPinned);
  };

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !isPinned
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPinned]);

  useEffect(() => {
    onStateChange(isOpen);
  }, [isOpen, onStateChange]);

  return (
    <>
      {isLargeScreen && (
        <Box
          ref={sidebarRef}
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            width: 261,
            height: "100vh",
            bgcolor: "#0A2F54",
            transition: "transform 0.3s ease-in-out",
            transform: isOpen ? "translateX(0)" : "translateX(-100%)",
            display: "flex",
            flexDirection: "column",
            zIndex: 999,
          }}
          onMouseEnter={() => !isPinned && setIsOpen(true)}
          onMouseLeave={() => !isPinned && setIsOpen(false)}
        >
          <Box
            sx={{
              height: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* This space is for the logo in the header */}
          </Box>

          <Box
            sx={{
              bgcolor: "#1A3A5A",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 1,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontWeight: 600,
                marginLeft: "5px",
              }}
            >
              History
            </Typography>

            <Tooltip title={isPinned ? "Unpin" : "Pin"}>
              <IconButton
                onClick={handlePin}
                sx={{
                  color: "white",
                  padding: 0,
                }}
              >
                <PushPinIcon
                  sx={{
                    transform: isPinned ? "rotate(45deg)" : "none",
                    fontSize: "1rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <PerfectScrollbar>
            <History></History>
          </PerfectScrollbar>
        </Box>
      )}

      {!isOpen && (
        <Tooltip title="History">
          <IconButton
            onClick={handleToggle}
            size="small"
            sx={{
              position: "fixed",
              left: 10,
              top: 65, // Adjusted to align with your layout
              bgcolor: "#0A2F54",
              color: "white",
              "&:hover": { bgcolor: "#1A3A5A" },
              display: { xs: "none", md: "flex" },
              zIndex: 1001,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default SIdebar;
