import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Modal,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Footer from "./Footer";
import { Authenticator } from "@aws-amplify/ui-react";
import { formFields, components } from "views/authentication/custom";

const PublicLayout = () => {
  const navigate = useNavigate();
  const tab1024 = useMediaQuery("(max-width:1024px)");
  const mob559 = useMediaQuery("(max-width:559px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPublicModalOpen, setIsPublicModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListItemClick = (event, route = "") => {
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const MenuStyle = {
    color: "#0D2751",
    borderRadius: "2em",
    paddingTop: "1em",
    paddingBottom: "1em",
    "&:hover": {
      background: "#1975D2",
      color: "#fff",
      opacity: 1,
    },
    "&:hover .MuiSvgIcon-root": {
      fill: "#fff",
    },
    "&:active": {
      background: "#1975D2",
      color: "#fff",
    },
  };
  return (
    <>
      <div className="w-full">
        <AppBar position="fixed" color="inherit" elevation={0}>
          <div
            className="bg-white flex min-[480px]:justify-between justify-center w-full lg:px-16 sm:px-8 px-4 lg:py-8 py-4 fixed top-0 left-0"
            style={{
              boxShadow: "0 4px 6px -1px rgba(0,0,0,0.1)",
            }}
          >
            <Link to="/">
              <div>
                <img src="/images/white_logo.svg" alt="" />
              </div>
            </Link>
            <div className="flex items-center max-[480px]:hidden">
              <NavLink
                to="/ai-explorer"
                className={({ isActive }) =>
                  `lg:text-lg text-base sm:ml-8 ml-4 ${isActive ? " font-semibold text-[#1975D2]" : "text-[#030C15]"} no-underline`
                }
              >
                AI Explorer
              </NavLink>
              <NavLink
                to="/how-it-works"
                className={({ isActive }) =>
                  `lg:text-lg text-base sm:ml-8 ml-4 ${isActive ? " font-semibold text-[#1975D2]" : "text-[#030C15]"} no-underline`
                }
              >
                How it works?
              </NavLink>
              <NavLink
                to="/pricing"
                className={({ isActive }) =>
                  `lg:text-lg text-base sm:ml-8 ml-4 ${isActive ? " font-semibold text-[#1975D2]" : "text-[#030C15]"} no-underline`
                }
              >
                Pricing
              </NavLink>

              <NavLink
                to="/"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "animate-pulse"
                    : isActive
                      ? "hidden"
                      : "inline sm:ml-8 ml-4"
                }
              >
                <Button
                  variant="contained"
                  size={tab1024 ? "small" : "large"}
                  className="bg-[#1975D2] rounded-[3em] lg:py-1 lg:px-6"
                >
                  Sign In
                </Button>
              </NavLink>
            </div>
            <IconButton
              className="absolute right-1 top-3 min-[480px]:hidden"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </AppBar>

        <Box className="bg-white w-full min-h-screen sm:pt-[106px] pt-[60px] pb-[48px] sm:px-11 px-4">
          <Outlet />
        </Box>
        <Footer />
        <Menu
          id="profile_menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "profile_menu_button",
            sx: {
              px: 2,
            },
          }}
          className="w-[40vh] p-6"
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            key="sldkjulkgfgf4"
            sx={MenuStyle}
            className="w-full flex items-center rounded-[2em]"
            onClick={(event) => handleListItemClick(event, "ai-explorer")}
          >
            <h3 className=" text-base font-medium"> AI Explorer</h3>
          </MenuItem>
          <MenuItem
            key="sldkjulkgfgf4"
            sx={MenuStyle}
            className="w-full flex items-center rounded-[2em]"
            onClick={(event) => handleListItemClick(event, "how-it-works")}
          >
            <h3 className=" text-base font-medium"> How it works?</h3>
          </MenuItem>
          <MenuItem
            key="sldkjdfgnsf3"
            sx={MenuStyle}
            className="w-full flex items-center rounded-[2em]"
            onClick={(event) => handleListItemClick(event, "pricing")}
          >
            <h3 className="text-base font-medium"> Pricing</h3>
          </MenuItem>

          <MenuItem
            key="sldasd3"
            sx={MenuStyle}
            className="w-full flex items-center rounded-[2em]"
            onClick={(event) => handleListItemClick(event, "/")}
          >
            <h3 className="text-base font-semibold"> Sign In</h3>
          </MenuItem>
        </Menu>
      </div>
      <Modal
        open={isPublicModalOpen}
        onClose={handleClose}
        aria-labelledby="signin-modal"
        aria-describedby="signin-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
            outline: "none",
          }}
        >
          <Authenticator formFields={formFields} components={components} />
        </Box>
      </Modal>
    </>
  );
};

export default PublicLayout;
